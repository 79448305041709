import React from 'react';
import styles from './Button.module.scss';
import cx from 'classix';
import CircularProgress from '@mui/material/CircularProgress';

const Button = ({ type, onClick, text, className, isLoading, picto, isDisabled, style }) => {
    const buttonClass = cx(
        styles.buttonBase,
        className
            .split(' ')
            .map((name) => styles[name])
            .join(' '),
        {
            [styles.disabled]: isDisabled || isLoading,
        }
    );    

    const handleOnClick = () => {
        if (!isLoading && !isDisabled) {
            onClick && onClick();
        }
    };

    return (
        <button
            type={type || 'button'}
            className={buttonClass}
            onClick={handleOnClick}
            disabled={isLoading || isDisabled}
            style={style}
        >
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                <>
                    {picto && <span className={styles.buttonIcon}>{picto}</span>}
                    {text}
                </>
            )}
        </button>
    );
};

export default Button;
