import * as yup from 'yup';

yup.addMethod(yup.string, 'validateSSN', function (errorMessage, specialErrorMessage) {
  return this.test('validateSSN', errorMessage, function (value) {
    const { path, createError } = this;

    // Vérifie si le numéro commence par 7 ou 8
    if (value.startsWith('7') || value.startsWith('8')) {
      return createError({
        path,
        message: specialErrorMessage || "Veuillez vous enregistrer au secrétariat du laboratoire.",
      });
    }

    const normalizedValue = normalizeCorsicanSSN(value);

    if (!normalizedValue || value.length !== 15) {
      return createError({
        path,
        message: errorMessage,
      });
    }

    const number = parseInt(normalizedValue.substring(0, 13), 10);
    const key = parseInt(normalizedValue.substring(13, 15), 10);

    if (97 - (number % 97) !== key) {
      return createError({
        path,
        message: errorMessage,
      });
    }

    return true;
  });
});

function normalizeCorsicanSSN(securityNumber) {
  if (/^(\d{6})[ABab](\d{8})$/.test(securityNumber)) {
    return securityNumber.replace('2A', '19').replace('2B', '18')
                        .replace('2a', '19').replace('2b', '18');
  }
  return securityNumber;
}

yup.addMethod(yup.date, 'transformDate', function (parseStrict) {
  return this.transform(function (value, originalValue, context) {

    if (typeof originalValue === 'string') {
      const parts = originalValue.split('/');
      if (parts.length === 3) {
        let day, month, year;
        if (parseStrict) {
          [day, month, year] = parts;
        } else {
          [month, day, year] = parts;
        }
        const parsedDate = new Date(year, month - 1, day);
        if (parsedDate && !isNaN(parsedDate)) {
          return parsedDate;
        }
      }
    }

    return new Date('');
  });
});

yup.addMethod(yup.date, 'minAge', function (minAge, message) {
  return this.test('minAge', message, function (birthdate) {
    const { path, createError } = this;
    let today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age--;
    }
    if (age < minAge) {
        return createError({ path, message: message});
    }

    return true;
  });
});

const identitySchema = yup.object({
  real_gender: yup.string().required("Entrez votre genre"),
  first_name: yup.string().required("Entrez votre prénom"),
  last_name: yup.string().required("Entrez votre nom de famille"),
  birth_name: yup.string().required("Entrez votre nom de naissance"),
  birthday: yup.date()
  .transformDate(true)
  .typeError('Format de date non valide')
  .nullable()
  .required('Entrer votre date de naissance')
  .minAge(18, "Veuillez vous enregistrer au secrétariat du laboratoire."),
  security_number: yup.string().required("Entrer votre numéro de sécurité social").validateSSN("Format du numéro de sécurité social invalide", "Veuillez vous enregistrer au secrétariat du laboratoire."),
  address: yup.string('').required('Entrer votre addresse'),
  address_complement: yup.string(),
  zip: yup.string().required('Entrez votre code postal'),
  city: yup.string().required('Entrez votre ville'),
  email: yup.string().email('Email non valide'),
  mobile: yup.string().required(/^(?:(?:\+|00)[1-9]\d{1,14}|0[1-9]\d{8})$/, 'Numéro de téléphone invalide.')
})

export default identitySchema