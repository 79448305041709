import { call, put, select } from "redux-saga/effects";
import { api_route, createApi } from "#services/api";
import { showToast } from '#utils/sweetAlert'
// Reducers
import { setLaboratories, setCurrentLab } from "#reducers/laboratoryReducer";
// import { nextStep } from "#reducers/sessionReducer"


export const getLaboratories = function* getLaboratories() {
  const api = createApi()
  const response = yield call(api.get, api_route.laboratories.index);
  if (response.ok && response.data && response.data.laboratories) {
    yield put(setLaboratories(response.data.laboratories));
  } else {
    showToast('error', 'Impossible de charger les laboratoires')
  }
};

// export const setLaboratory = function* setLaboratory({ laboratory }) {
//   const api = createApi()
//   const record = yield select(selectRecord)
//   const response = yield call(api.post, api_route.records.save_laboratory(record.id), {laboratory});
//   if (response.ok) {
//     yield put(setCurrentLab(laboratory))
//     yield put(nextStep())
//   } else {
//     showToast('error', 'Impossible de sauvegarder le laboratoire')
//   }
// };
