const initialState = {
  list: [],
  laboratory: null
};

const SET_LABORATORIES = 'SET_LABORATORIES';
const SET_LABORATORY = 'SET_LABORATORY';
const RESET_LABORATORY = 'RESET_LABORATORY';

const laboratoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LABORATORIES: {
      return { ...state, list: action.laboratories };
    }
    case SET_LABORATORY: {
      return { ...state, laboratory: action.laboratory };
    }
    case RESET_LABORATORY: {
      return { ...state, laboratory: null };
    }
    default:
      return state;
  }
};

export default laboratoryReducer;

export const setLaboratories = (laboratories) => ({
  type: SET_LABORATORIES,
  laboratories
});

export const setLaboratory = (laboratory) => ({
  type: SET_LABORATORY,
  laboratory
});

export const resetLaboratory = () => ({
  type: RESET_LABORATORY
});
