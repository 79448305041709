import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// Reducers
import { prevStep, nextStep } from '#reducers/sessionReducer'
// Utils
import { useCanGoStep } from '#utils/useCanGoStep'
//Styles
import styles from './Form.module.scss'

const FormWrapper = ({title, children, lockNext}) => {
  const dispatch = useDispatch()
  const [ onBack, onForward ] = useCanGoStep()
  const isDocumentSelectionStep = useSelector((state) => state.sessions.isDocumentSelectionStep);

  const goBack = () => {
    dispatch(prevStep())
  }

  const goNext = () => {
    dispatch(nextStep())
  }

  return (
    <div className={styles.formContainer}>
      <h2>Votre enregistrement en ligne</h2>
      <div className={styles.formHeader}>
        {onBack && !isDocumentSelectionStep ? (
          <div className={styles.back} onClick={goBack}><ArrowBackIcon /></div>
        ) : (
          <div className={styles.back} />
        )}
        <h3 className={styles.title}>{title}</h3>
        {onForward && !lockNext? (
          <div className={styles.next} onClick={goNext}><ArrowForwardIcon /></div>
        ) : (
          <div className={styles.next} />
        )}
      </div>
      <div className={styles.formContent}>{children}</div>
    </div>
  );
};

export default FormWrapper