import cx from 'classix';
// Components
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Styles
import styles from './Alert.module.scss';
//Utils
import useIsDesktop from '#utils/useInDesktop'

export const Alert = ({ message, type, style, desktopWidth  }) => {
  const isDesktop = useIsDesktop();
  const cardStyle = isDesktop && desktopWidth ? { width: `${desktopWidth}%` } : {};

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon />;
      case 'error':
        return <ErrorIcon />;
      default:
        return <WarningIcon />;
    }
  }

  return (
    <div className={cx(styles.alert, styles[`alert-${type}`])} style={cardStyle}>
      {getIcon()}
      {message}
    </div>
  );
}

export default Alert;