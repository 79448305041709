const initialState = {
  parcours: null,
  biologicalAssessment: null,
  biologicalAssessments: [],
  currentSteps: ['parcours'],
  currentStep: 'parcours',
  exclusionCriteria: null,
  criteria: [],
  cgu: false,
  registrationFinished: false,
  config_steps: {},
  steps: [],
  documents: [],
  isDocumentSelectionStep: false
};

// Action types
const SET_PARCOURS = 'SET_PARCOURS';
const SET_CONFIG_STEPS = 'SET_CONFIG_STEPS';
const SET_BIOLOGICAL_ASSESSMENT = 'SET_BIOLOGICAL_ASSESSMENT';
const SET_BIOLOGICAL_ASSESSMENTS = 'SET_BIOLOGICAL_ASSESSMENTS';
const EXCLUDE_PATIENT = 'EXCLUDE_PATIENT';
const INCLUDE_PATIENT = 'INCLUDE_PATIENT';
const SET_CGU = 'SET_CGU';
const NEXT_STEP = 'NEXT_STEP';
const PREV_STEP = 'PREV_STEP';
const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';
const FINISHED = 'FINISHED';
const SET_NPS = 'SET_NPS';
const SET_DOCUMENTS = 'SET_DOCUMENTS';
const SHOW_CONTEXT = 'SHOW_CONTEXT';
const SET_DOCUMENT_SELECTION_STEP = 'SET_DOCUMENT_SELECTION_STEP';

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARCOURS: {
      const steps = action.parcours === "with_prescription" ? state.config_steps.with_prescription : state.config_steps.without_prescription;
      return { ...state, parcours: action.parcours, steps, biologicalAssessment: null };
    }
    case SET_BIOLOGICAL_ASSESSMENT: {
      return { ...state, biologicalAssessment: action.biologicalAssessment };
    }
    case SET_BIOLOGICAL_ASSESSMENTS: {
      return { ...state, biologicalAssessments: action.biologicalAssessments };
    }
    case SET_CONFIG_STEPS: {
      return { ...state, config_steps: action.steps };
    }
    case EXCLUDE_PATIENT: {
      return { ...state, exclusionCriteria: 'yes' };
    }
    case INCLUDE_PATIENT: {
      return { ...state, exclusionCriteria: 'no' };
    }
    case SET_CGU: {
      return { ...state, cgu: true };
    }
    case NEXT_STEP: {
      return state;
    }
    case PREV_STEP: {
      return state;
    }
    case UPDATE_NAVIGATION: {
      return { ...state, currentStep: action.currentStep, currentSteps: action.currentSteps };
    }
    case FINISHED: {
      return { ...state, registrationFinished: true };
    }
    case SET_NPS: {
      return state;
    }
    case SET_DOCUMENTS: {
      return { ...state, documents: action.documents };
    }

    case SET_DOCUMENT_SELECTION_STEP: {
      return {...state, isDocumentSelectionStep: action.payload };
    }

    default:
      return state;
  }
};

export default sessionReducer;

export const setParcours = (parcours) => {
  return {
    type: SET_PARCOURS,
    parcours,
  };
};

export const setBiologicalAssessment = (biologicalAssessment) => {
  return {
    type: SET_BIOLOGICAL_ASSESSMENT,
    biologicalAssessment,
  };
};

export const setBiologicalAssessments = (biologicalAssessments) => {
  return {
    type: SET_BIOLOGICAL_ASSESSMENTS,
    biologicalAssessments,
  };
};

export const setConfigSteps = (steps) => {
  return {
    type: SET_CONFIG_STEPS,
    steps,
  };
}

export const excludePatient = () => {
  return {
    type: EXCLUDE_PATIENT
  };
};

export const includePatient = () => {
  return {
    type: INCLUDE_PATIENT
  };
};

export const setCgu = () => {
  return {
    type: SET_CGU,
  };
};

export const nextStep = () => {
  return {
    type: NEXT_STEP
  };
};

export const prevStep = () => {
  return {
    type: PREV_STEP
  };
};

export const updateNavigation = (currentStep, currentSteps) => {
  return {
    type: UPDATE_NAVIGATION,
    currentStep,
    currentSteps
  };
};

export const finished = () => {
  return {
    type: FINISHED
  }
}

export const setNps = (nps) => {
  return {
    type: SET_NPS,
    nps
  }
}

export const setDocuments = (documents) => {
  return {
    type: SET_DOCUMENTS,
    documents,
  };
};

export const showContext = () => {
  return {
    type: SHOW_CONTEXT
  };
}

export const setDocumentSelectionStep = (isDocumentSelectionStep) => ({
  type: SET_DOCUMENT_SELECTION_STEP,
  payload: isDocumentSelectionStep,
});