const convertToUTC = (dateString) => {
  const date = new Date(dateString);
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return utcDate.toISOString().split('T')[0];
};

function formatDate(date) {
  if (!(date instanceof Date)) return '';
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() est basé sur zéro
  let year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

const dateUtils = {
  convertToUTC,
  formatDate
}

export default dateUtils