import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import FormWrapper from './forms/FormWrapper';
import ParcoursForm from "./forms/ParcoursForm";
import LaboratoryForm from "./forms/LaboratoryForm";
import CriteriaForm from "./forms/CriteriaForm";
import IdentityForm from "./forms/IdentityForm";
import MutuelleForm from "./forms/MutuelleForm";
import PrescriptionForm from "./forms/PrescriptionForm";
import ContextForm from "./forms/ContextForm";
import ConfirmationForm from "./forms/ConfirmationForm";
// Selectors
import { contentSelector, laboratorySelector, biologicalsSelector } from "#reducers/selectors";
import { nextStep } from '#reducers/sessionReducer';
// Styles
import styles from "./Content.module.scss";
import NavIconsMobile from "../nav/NavIconsMobile";

const Content = () => {
  const dispatch = useDispatch();
  const biologicals = useSelector(biologicalsSelector);
  const prescriptions = useSelector(state => state.records.prescriptions);
  const { step, mutuelle, prescription, exclusionCriteria } = useSelector(contentSelector);
  const laboratory = useSelector(laboratorySelector);

  const _renderParcoursForm = () => { 
    return (
      <FormWrapper 
        title="Choisissez votre parcours"
      >
        <ParcoursForm />
      </FormWrapper>
    );
  };

  const _renderLaboratoryForm = () => {
    return (
      <FormWrapper 
        title="Choisissez votre laboratoire"
        lockNext={laboratory === null}
      >
        <LaboratoryForm />
      </FormWrapper>
    );
  };

  const _renderCriteriaForm = () => {
    return (
      <FormWrapper 
        title="Indiquez votre situation"
        lockNext={exclusionCriteria === 'yes'}
      >
        <CriteriaForm />
      </FormWrapper>
    );
  };
  
  const _renderIdentityForm = () => {
    return (
      <FormWrapper 
      title="Renseignez vos informations personnelles"
      >
        <IdentityForm />
      </FormWrapper>
    );
  };

  const _renderMutuelleForm = () => {
    return (
      <FormWrapper 
        title="Votre document de prise en charge"
        lockNext={!mutuelle || !mutuelle.valid}
        >
        <MutuelleForm />
      </FormWrapper>
    );
  };

  const _renderPrescriptionForm = () => {
    return (
      <FormWrapper 
        title="Votre ordonnance"
        lockNext={!prescription || !prescription.valid}
        >
        <PrescriptionForm />
      </FormWrapper>
    );
  };

  const _renderContextForm = () => {
    const lastPrescription = prescriptions[prescriptions.length - 1];

    // Vérifie si la prescription a des codes LOINC associés à des biologicals avec des questions contextuelles
    const hasContextQuestions = Array.isArray(lastPrescription?.loinc_codes) && 
      lastPrescription.loinc_codes.some(code => {
        const biological = biologicals.find(b => b.code === code);
        return biological && biological.context_questions && biological.context_questions.length > 0;
      });

    // Si des questions contextuelles existent, afficher le formulaire
    if (hasContextQuestions) {
      return (
        <FormWrapper title="Questions complémentaires à vos analyses">
          <ContextForm />
        </FormWrapper>
      );
    }

    // Si aucune question contextuelle, on passe directement à l'étape suivante
    dispatch(nextStep());
    return null; // N'affiche rien si on saute l'étape 'context'
  };

  const _renderConfirmationForm = () => {
    return <ConfirmationForm />;
  };
  
  const _renderForm = () => {
    switch (step) {
      case 'parcours':
        return _renderParcoursForm();
      case 'laboratory':
        return _renderLaboratoryForm();
      case 'criteria':
        return _renderCriteriaForm();
      case 'identity':
        return _renderIdentityForm();
      case 'mutuelle':
        return _renderMutuelleForm();
      case 'prescription':
        return _renderPrescriptionForm();
      case 'context':
        return _renderContextForm();
      case 'confirmation':
        return _renderConfirmationForm();
      default:
        /* TODO: error pages */
        break;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <NavIconsMobile />
        <div className={styles.formContainer}>
          {_renderForm()}
        </div>
      </div>
    </>
  );
};

export default Content;
