import React from "react";
// Redux
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// Actions
import { setParcours, setBiologicalAssessment } from "#reducers/sessionReducer";
import { resetLaboratory } from "#reducers/laboratoryReducer.js";
// selectors
import { biologicalAssessmentsSelector, biologicalAssessmentSelector } from "#reducers/selectors";
// Components
import ParcoursCard from "#components/global/ParcoursCard";
import BiologicalAssessmentCard from "#components/global/BiologicalAssessmentCard";
// Styles
import styles from "./Form.module.scss";

const ParcoursForm = () => {
  const dispatch = useDispatch();
  const parcours = useSelector((state) => state.sessions.parcours);
  const selectedBiologicalAssessment = useSelector(biologicalAssessmentSelector, shallowEqual);
  const biologicalAssessments = useSelector(biologicalAssessmentsSelector, shallowEqual);
  const isAnyCardSelected = parcours !== null;

  const handleParcoursSelection = (parcoursType) => {
    dispatch(setParcours(parcoursType));
    dispatch(resetLaboratory());
  };

  const handleBiologicalAssessmentSelection = (assessment) => {
    dispatch(setBiologicalAssessment(assessment));
    dispatch(resetLaboratory());
  };

  return (
    <div className={styles.formContainerColumn}>
      <div className={styles.cardsContainer}>
        <ParcoursCard
          title="Avec ordonnance"
          content="Vous aurez besoin de : votre carte de mutuelle, votre ordonnance"
          onClick={() => handleParcoursSelection("with_prescription")}
          color="#E53012"
          isSelected={parcours === "with_prescription"}
          isAnyCardSelected={isAnyCardSelected}
        >
          <p>
            Vous aurez besoin de :
            <br />Votre <strong>carte de mutuelle</strong> ou <strong>document de prise en charge</strong>
            <br />Votre <strong>ordonnance</strong>
          </p>
        </ParcoursCard>
        <ParcoursCard
          title="Sans ordonnance"
          onClick={() => handleParcoursSelection("without_prescription")}
          color="#F7A703"
          isSelected={parcours === "without_prescription"}
          isAnyCardSelected={isAnyCardSelected}
        >
          <p>Certains examens médicaux ne nécessitant pas d’ordonnance sont proposés dans nos laboratoires.</p>
        </ParcoursCard>
      </div>

      {parcours === "without_prescription" && (
        <>
          <h3 className="mt-3" style={{ marginBottom: "2rem" }}>
            Choisissez votre analyse
          </h3>
          <div className={styles.cardsContainer}>
            {biologicalAssessments.map((assessment) => (
              <BiologicalAssessmentCard
                key={assessment.code_ba}
                title={assessment.label}
                content={assessment.description}
                color="#DE3C20"
                onClick={() => handleBiologicalAssessmentSelection(assessment)}
                isSelected={selectedBiologicalAssessment && selectedBiologicalAssessment.code_ba === assessment.code_ba}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ParcoursForm;
