import styles from './NavIconsMobile.module.scss';
import { useSelector } from 'react-redux';
//Images
import { locationOffIcon, locationOnIcon, identityOffIcon, identityOnIcon, mutuelleOffIcon, mutuelleOnIcon, prescriptionOffIcon, prescriptionOnIcon, biologicalAssessmentOffIcon, biologicalAssessmentOnIcon} from "#images";
//Components
import NavState from "./NavState";
// Selectors
import { laboratorySelector, patientSelector, mutuelleSelector, prescriptionSelector } from "#reducers/selectors"

const NavIconsMobile = () => {
  const laboratory = useSelector(laboratorySelector)
  const patient = useSelector(patientSelector)
  const mutuelle = useSelector(mutuelleSelector)
  const prescription = useSelector(prescriptionSelector)
  const parcours = useSelector(state => state.sessions.parcours);
  const biologicalAssessment = useSelector(state => state.sessions.biologicalAssessment);

  return (
    <div className={styles.NavIconsMobileContainer}>
      {parcours === 'without_prescription' && (
        <div className={styles.gridItem}>
          <NavState
            object={biologicalAssessment ? { name: biologicalAssessment } : null}
            icon={<img src={biologicalAssessmentOffIcon} className={styles.iconState} alt='biologicalAssessment icon' />}
            activeIcon={<img src={biologicalAssessmentOnIcon} className={styles.iconState} alt='biologicalAssessment icon' />}
          >
          </NavState>
        </div>
      )}
      <div className={styles.gridItem}>
        <NavState
          object={laboratory}
          icon={<img src={locationOffIcon} className={styles.iconState} alt='laboratory icon' />}
          activeIcon={<img src={locationOnIcon} className={styles.iconState} alt='laboratory icon' />}
        >
        </NavState>
      </div>
      <div className={styles.gridItem}>
        <NavState
            object={patient}
            icon={<img src={identityOffIcon} className={styles.iconState} alt='identity icon' />}
            activeIcon={<img src={identityOnIcon} className={styles.iconState} alt='identity on icon' />}
            specificFieldCheck={patient && patient.last_name && patient.first_name }
          >
        </NavState>
      </div>
      {parcours === 'with_prescription' && (
        <>
          <div className={styles.gridItem}>
            <NavState
              object={mutuelle}
              icon={<img src={mutuelleOffIcon} className={styles.iconState} alt='mutuelle icon' />}
              activeIcon={<img src={mutuelleOnIcon} className={styles.iconState} alt='mutuelle icon' />}
            >
            </NavState>
          </div>
          <div className={styles.gridItem}>
            <NavState
              object={prescription}
              icon={<img src={prescriptionOffIcon} className={styles.iconState} alt='Prescription icon' />}
              activeIcon={<img src={prescriptionOnIcon} className={styles.iconState} alt='Prescription icon' />}
            >
            </NavState>
          </div>
        </>
      )}
    </div>
  );
};

export default NavIconsMobile;
