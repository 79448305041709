import { call, put, select } from 'redux-saga/effects';
import { api_route, createApi } from "#services/api";
import { showToast } from '#utils/sweetAlert';
// Actions
import { saveMutuelle, savePrescription, savePatientDocument, setError } from '#reducers/fileReducer';
import { setDocuments } from '#reducers/sessionReducer';

const selectPatient = (state) => state.records.patient;

export const uploadMutuelle = function* uploadMutuelle({file}) {
  const patient = yield select(selectPatient);
  const response = yield call(uploadMutuelleToApi, file, patient);
  if(response.ok && response.data) {
    const mutuelle = response.data;
    const isValid = yield call(checkIsValidMutuelle, mutuelle);
    mutuelle.valid = isValid;
    yield put(saveMutuelle(mutuelle));
  } else {
    yield put(setError());
    showToast('error', 'Impossible de sauvegarder votre mutuelle');
  }
};

function checkIsValidMutuelle(mutuelle) {
  return mutuelle.validity_score >= 3;
}

function uploadMutuelleToApi(file, patient) {
  const api = createApi({headers: { 'Content-Type': 'multipart/form-data' }, timeout: 15000});
  const formData = new FormData();
  formData.append('file', file);
  formData.append('patient_id', patient.id);
  return api.post(api_route.files.save_mutuelle, formData);
}

export const deleteMutuelle = function* deleteMutuelle({ mutuelleId }) {
  const api = createApi();
  const response = yield call(api.delete, api_route.files.delete_mutuelle, { mutuelle_id: mutuelleId });
  if (!response.ok) {
    showToast('error', 'Failed to delete the mutuelle');
  }
};

export const uploadPrescription = function* uploadPrescription({file}) {
  const patient = yield select(selectPatient);
  const response = yield call(uploadPrescriptionToApi, file, patient);
  if(response.ok && response.data) {
    const prescription = response.data;
    const isValid = yield call(checkIsValidPrescription, prescription);
    const verification = yield call(verifyPrescriptionInformation, prescription);
    prescription.valid = isValid;
    prescription.verification = verification;
    yield put(savePrescription(prescription));
  } else {
    yield put(setError());
    showToast('error', 'Impossible de sauvegarder votre ordonnance');
  }
};

export const updatePrescriptionSaga = function* updatePrescriptionSaga({prescription}) {
  const api = createApi();
  if (!prescription.id) {
    console.error("L'ID de la prescription est undefined dans la saga");
    return;
  }
  const response = yield call(api.put, `/api_web/v1/prescriptions/${prescription.id}`, { prescription });

  if (response.ok) {
    showToast('success', 'Prescription updated successfully');
  } else {
    showToast('error', 'Failed to update prescription');
    yield put(setError());
  }
};

function checkIsValidPrescription(prescription) {
  return (!!prescription.loinc_codes && prescription.loinc_codes.length > 0) || (prescription.prescriptors_found && prescription.prescriptors_found.length > 0) || prescription.find_patient_last_name;
}

function verifyPrescriptionInformation(prescription) {
  const result = {
    firstNameFound: prescription.find_patient_first_name,
    lastNameFound: prescription.find_patient_last_name,
    parsedText: prescription.parsed_text,
    prescription_date: prescription.prescription_date,
    prescriptor: prescription.prescriptor,
    prescriptors_found: prescription.prescriptors_found
  };
  return result;
}

function uploadPrescriptionToApi(file, patient) {
  const api = createApi({headers: { 'Content-Type': 'multipart/form-data' }, timeout: 15000});
  const formData = new FormData();
  formData.append('file', file);
  formData.append('patient_id', patient.id);
  return api.post(api_route.files.save_prescription, formData);
}

export const uploadPatientDocument = function* uploadPatientDocument({ file, documentCode, fileName }) {
  const patient = yield select(selectPatient);
  const response = yield call(uploadPatientDocumentToApi, file, documentCode, fileName, patient);
  if (response.ok && response.data) {
    const patientDocument = response.data.patient_document;
    yield put(savePatientDocument(patientDocument));
  } else {
    yield put(setError());
    showToast('error', 'Unable to save the document');
  }
};

function uploadPatientDocumentToApi(file, documentCode, fileName, patient) {
  const api = createApi({ headers: { 'Content-Type': 'multipart/form-data' }, timeout: 15000 });
  const formData = new FormData();
  formData.append('file', file);
  formData.append('patient_id', patient.id);
  formData.append('document_code', documentCode);
  formData.append('file_name', fileName);
  return api.post(api_route.files.save_patient_document, formData);
}

export const deletePatientDocument = function* deletePatientDocument({ patientDocumentId }) {
  if (!patientDocumentId) {
    console.error("patientDocumentId is undefined");
    yield put(setError());
    return;
  }

  const api = createApi();
  const patient = yield select(selectPatient);
  const response = yield call(api.delete, api_route.files.delete_patient_document, {
    patient_id: patient.id,
    patient_document_id: patientDocumentId
  });

  if (!response.ok) {
    yield put(setError());
    showToast('error', 'Failed to delete the document');
  }
};

function fetchDocumentsFromApi(laboratoryCode) {
  const api = createApi();
  return api.get(`/api/v1/configs/documents?laboratory_code=${laboratoryCode}`);
}

export function* syncDocuments({ laboratoryCode }) {
  const response = yield call(fetchDocumentsFromApi, laboratoryCode);
  if (response.ok && response.data) {
    const documents = response.data.documents;
    yield put(setDocuments(documents));
  } else {
    console.error('Failed to fetch documents');
  }
}
