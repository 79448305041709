import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Grid, MenuItem, TextField, RadioGroup, FormControlLabel, Radio, Typography  } from "@mui/material";

import Cleave from "cleave.js/react";
import 'cleave.js/dist/addons/cleave-phone.fr';
import cx from 'classix';

// Components
import Button from "#components/global/Button";
import DateField from "#components/global/DateField";
// Reducer
import { setPatient } from "#reducers/recordReducer";
import { patientSelector } from "#reducers/selectors";
import { nextStep } from "#reducers/sessionReducer.js";
// Schema
import { yupResolver } from "@hookform/resolvers/yup";
import identitySchema from "#validations/identityValidationSchema";
// Utils
import dateUtils from "#utils/dateUtils";
//Styles
import styles from "./Form.module.scss";

const genderOptions = [
  { value: "man", label: "Homme" },
  { value: "woman", label: "Femme" },
  { value: "unknown", label: "Autre" },
];

const MobileInput = React.memo(
  React.forwardRef(({ onChange, ...props }, inputRef) => {
    const handleOnChange = (e) => {
      let value = e.target.rawValue.trim();

      // Nettoyage des espaces pour une meilleure gestion du numéro.
      value = value.replace(/\s+/g, '');

      // Appelle onChange avec la valeur nettoyée
      onChange(value);
    };

    return (
      <Cleave
        {...props}
        ref={props.inputRef}
        options={{
          phone: true,
          phoneRegionCode: '', // Accepte tous les formats sans restriction régionale
          prefix: '',          // Pas de préfixe affiché par défaut
          delimiter: ' ',      // Ajoute des espaces pour une meilleure lisibilité
          rawValueTrimPrefix: true, // Trim le préfixe pour ne pas garder les "+" automatiquement
        }}
        onChange={handleOnChange}
      />
    );
  })
);


const TextInputField = ({ control, name, label, required, trim, gridSize, disabled }) => {
  // Classe conditionnelle pour champ désactivé
  const fieldClassName = disabled ? styles.disabledField : "";
  return (
    <Grid item xs={12} md={gridSize || 6}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          name === 'mobile' ? (
            <TextField
              {...field}
              InputProps={{
                inputComponent: MobileInput
              }}
              label={label}
              inputRef={field.ref}
              error={Boolean(fieldState?.error)}
              helperText={fieldState?.error?.message}
              color="secondary"
              required={required}
              disabled={disabled} 
              className={cx(fieldClassName)}
            />
          ) : (
          <TextField
            {...field}
            onChange={(e) => {
              let value = e.target.value;
              if(trim) value = value.replace(/\s/g, '');
              field.onChange(value);
            }}
            label={label}
            error={Boolean(fieldState?.error)}
            helperText={fieldState?.error?.message}
            color="secondary"
            required={required}
            disabled={disabled} 
            className={cx(fieldClassName)}
          />
          )
        )}
      />
    </Grid>
  );
};

const SelectField = ({ control, name, label, required, gridSize }) => {
  return (
    <Grid item xs={12} md={gridSize || 6}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            select
            label={label}
            error={Boolean(fieldState?.error)}
            helperText={fieldState?.error?.message}
            color="secondary"
            required={required}
          >
            <MenuItem value="" disabled />
            {genderOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
};

const RadioField = ({ control, name, label, gridSize }) => {
  return (
    <Grid item xs={12} md={gridSize || 6} className={styles.radioFieldContainer}>
      <Typography variant="subtitle1" className={styles.radioLabel}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} row>
            <FormControlLabel
              value="yes"
              control={<Radio color="secondary" />}
              label={<span className={styles.radioOptionLabel}>Oui</span>}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="secondary" />}
              label={<span className={styles.radioOptionLabel}>Non</span>}
            />
          </RadioGroup>
        )}
      />
    </Grid>
  );
};

const IdentityForm = () => {
  const dispatch = useDispatch();
  const patient = useSelector(patientSelector);
  const lsPatient = JSON.parse(sessionStorage.getItem("identityForm") || "{}")
  const patientBirthday = lsPatient?.birthday || patient.birthday
  const defaultIdenticalBirthname = patient.birth_name === patient.last_name ? "yes" : "no";

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(identitySchema),
    defaultValues: {
      ...patient,
      ...lsPatient,
      birthday: dateUtils.formatDate(new Date(patientBirthday)),
      identical_birthname: defaultIdenticalBirthname,
      birth_name: patient.birth_name
    },
  });

  const identicalBirthname = watch("identical_birthname");
  const lastName = watch("last_name");
  // Fonction qui met à jour birth_name lorsque lastName change et identicalBirthname est "yes"
  useEffect(() => {
    if (identicalBirthname === "yes") {
      setValue("birth_name", lastName);
    }else if (identicalBirthname === "no" && !patient.birth_name) {
      setValue("birth_name", "");
    }
  }, [lastName, identicalBirthname, patient.birth_name]);  

  // Save form state in localstorage
  const currentFormData = watch();
  useEffect(() => {
    sessionStorage.setItem("identityForm", JSON.stringify(currentFormData));
  }, [currentFormData]);

  const onSubmit = (data) => {
    data.birthday = dateUtils.convertToUTC(data.birthday);
    data.identical_birthname = data.identical_birthname === 'yes' // sauvergarde le boolean dans le store de l'application (qui renvoie ensuite en saga)
    dispatch(setPatient(data));
    dispatch(nextStep());
    localStorage.removeItem("identityForm");
  };

  return (
    <form className={styles.identityForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        className={styles.identityGrid}
      >
        <SelectField
          control={control}
          name="real_gender"
          label="Sexe"
          required
          gridSize={2}
        />
        <TextInputField
          control={control}
          name="first_name"
          label="Prénom"
          required
          gridSize={4}
        />
        <TextInputField
          control={control}
          name="last_name"
          label="Nom de famille"
          required
        />
        <RadioField
          control={control}
          name="identical_birthname"
          label="Nom de naissance identique au nom de famille :"
          required
        />
        <TextInputField
          control={control}
          name="birth_name"
          label="Nom de naissance"
          disabled={identicalBirthname === "yes"}
          required
        />
        <DateField
          control={control}
          name="birthday"
          label="Date de naissance"
          required
        />
        <TextInputField
          control={control}
          name="security_number"
          label="N° sécurité sociale"
          required
        />
        <TextInputField
          control={control}
          name="address"
          label="Adresse"
          gridSize={12}
          required
        />
        <TextInputField
          control={control}
          name="zip"
          label="Code postal"
          required
        />
        <TextInputField control={control} name="city" label="Ville" required />
        <TextInputField control={control} name="email" label="Email" required trim />
        <TextInputField
          control={control}
          name="mobile"
          label="Numéro de téléphone"
          required
        />
      </Grid>
      <div className="single-btn-container btn-center">
        <Button className="primary" type="submit" text="ENREGISTRER" />
      </div>
    </form>
  );
};

export default IdentityForm;
