const initialState = {
  criteria: [],
  biologicals: []
};

const GET_CRITERIA = 'GET_CRITERIA';
const SET_CRITERIA = 'SET_CRITERIA';
const GET_BIOLOGICALS = 'GET_BIOLOGICALS';
const SET_BIOLOGICALS = 'SET_BIOLOGICALS';

export default function biologicalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CRITERIA:
      return state;
    case SET_CRITERIA:
      return {
        ...state,
        criteria: action.criteria
      };
    case GET_BIOLOGICALS:
      return state;
    case SET_BIOLOGICALS:
      return {
        ...state,
        biologicals: action.biologicals
      };
    default:
      return state;
  }
}

export const getCriteria = (code_ba) => ({
  type: GET_CRITERIA,
  code_ba
});

export const setCriteria = (criteria) => ({
  type: SET_CRITERIA,
  criteria
});

export const getBiologicals = (biologicals) => ({
  type: GET_BIOLOGICALS,
  biologicals
});

export const setBiologicals = (biologicals) => ({
  type: SET_BIOLOGICALS,
  biologicals
});
