export const formatContextAnswers = (contextAnswers, biologicals) => {
  const formattedAnswers = [];

  console.log("****** Réponses collectées avant formatage ****** :", contextAnswers);

  // Récupérer toutes les questions contextuelles avec leurs identifiants réels
  const allContextQuestions = biologicals.flatMap(bio => bio.context_questions);

  // Filtrer les réponses avec des identifiants valides
  const validContextAnswers = contextAnswers.filter(answerData =>
    allContextQuestions.some(q => q.id === answerData.context_question_id)
  );

  validContextAnswers.forEach((answerData) => {
    const questionIdNum = answerData.context_question_id;
    const question = allContextQuestions.find(q => q.id === questionIdNum);
    if (!question) {
      console.warn(`⚠️ Question ID ${questionIdNum} introuvable`);
      return;
    }

    // Formatage selon le type de question
    switch (question.question_type) {
      case 'text':
        formattedAnswers.push({
          context_question_id: questionIdNum,
          context_value_id: null, // Pas de valeur ID pour les réponses textuelles
          context_value_label: answerData.context_value_label || "",
          answer_value: question.question_label,
        });
        break;

      case 'radio':
        formattedAnswers.push({
          context_question_id: questionIdNum,
          context_value_id: answerData.context_value_id,
          context_value_label: "", // Le label sera rempli côté backend si nécessaire
          answer_value: question.question_label,
        });
        break;

      case 'checkbox':
        if (Array.isArray(answerData.context_value_id)) {
          answerData.context_value_id.forEach((valueId) => {
            formattedAnswers.push({
              context_question_id: questionIdNum,
              context_value_id: valueId,
              context_value_label: "", // Le label sera rempli côté backend si nécessaire
              answer_value: question.question_label,
            });
          });
        }
        break;

      default:
        console.warn(`⚠️ Type de question non supporté : ${question.question_type}`);
    }
  });

  console.log("****** Réponses formatées finales ****** :", formattedAnswers);
  return formattedAnswers;
};
