import { createSelector } from 'reselect';

export const patientSelector = createSelector(
  state => state.records.patient,
  patient => patient 
);

export const laboratorySelector = createSelector(
  state => state.laboratories.laboratory,
  laboratory => laboratory
);

export const laboratoriesSelector = createSelector(
  state => state.laboratories.list,
  laboratories => laboratories
);

export const mutuelleSelector = createSelector(
  state => state.files.mutuelle,
  mutuelle => mutuelle
);

export const prescriptionSelector = createSelector(
  state => state.files.prescription,
  prescription => prescription
);

export const biologicalAssessmentSelector = createSelector(
  state => state.sessions.biologicalAssessment,
  biologicalAssessment => biologicalAssessment
);

export const biologicalAssessmentsSelector = createSelector(
  state => state.sessions.biologicalAssessments,
  biologicalAssessments => biologicalAssessments
);

// Sélecteur pour les types de documents (configurations)
export const documentTypesSelector = createSelector(
  state => state.sessions.documents,
  documents => documents
);

// Sélecteur pour les documents des patients
export const patientDocumentsSelector = state => state.files.patientDocuments;


export const contentSelector = createSelector(
  state => state.sessions.currentStep,
  state => state.files.mutuelle,
  state => state.files.prescription,
  state => state.sessions.exclusionCriteria,
  state => state.laboratories.laboratory,
  (step, mutuelle, prescription, exclusionCriteria, laboratory) => ({
    step,
    mutuelle,
    prescription,
    exclusionCriteria,
    laboratory
  })
);

export const biologicalsSelector = createSelector(
  state => state.biological.biologicals,
  biologicals => biologicals
);

export const contextAnswersSelector = createSelector(
  state => state.records.contextAnswers,
  contextAnswers => contextAnswers || {}
);
