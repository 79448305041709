import React, { useState, useEffect  } from 'react';
import { useSelector, useDispatch } from "react-redux";
import cx from "classix";
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Reducer
import { uploadPrescription } from "#reducers/fileReducer";
import { updatePatient, updateVerification } from '#reducers/recordReducer';
import { prescriptionSelector, patientSelector } from '#reducers/selectors';
//Components
import Button from "#components/global/Button";
import PartialIdentityForm from './PartialIdentityForm';
import PartialPrescriptionForm from './PartialPrescriptionForm'
import Alert from '#components/global/Alert';
import LargeWhiteCard from '#components/global/LargeWhiteCard';
import PreviewCard from '#components/global/PreviewCard';
import InfoIcon from "@mui/icons-material/Info";
// Utils
import { useFileHandling } from "#utils/useFileHandling";
import { showImage } from '#utils/sweetAlert';
// Services
import { api_route } from '#services/api';
// Styles
import styles from "./Form.module.scss";
//Images
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import prescriptionPicto from '#assets/images/prescription_picto.png';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from "@mui/material";

const PrescriptionForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch()
  const prescription = useSelector(prescriptionSelector)
  const patient = useSelector(patientSelector)
  const loading = useSelector(state => state.files.loading)
  const {
    file,
    previewImage,
    handleFileChange,
    triggerFileSelect,
    clearImage,
    removeStoredFile,
    isPdf
  } = useFileHandling("fileInput");
  const verification = useSelector((state) => state.records.verification); // Lecture directe depuis le `store`

  useEffect(() => {
    if (prescription) {
      const updatedVerification = {
        firstNameFound: prescription.parsed_text?.toLowerCase().includes(patient.first_name.toLowerCase()) || false,
        lastNameFound: prescription.parsed_text?.toLowerCase().includes(patient.last_name.toLowerCase()) || false,
      };
      dispatch(updateVerification(updatedVerification));
    }
  }, [patient.first_name, patient.last_name, prescription, dispatch]);

  const onSubmit = () => {
    dispatch(uploadPrescription(file))
  }
  const defineStep = () => {
    let step = 'select'
    prescription && (step = 'validation')
    !prescription && previewImage && (step = 'preview')
    return step
  }
  const renderStep = () => {
    const step = defineStep()
    switch(step) {
      case 'select':
        return _renderSelect();
      case 'preview':
        return _renderPreview();
      case 'validation':
        return _renderFileValidation();
      default:
        return null;
    }
  };
  const handleIdentityCorrectionClick = () => {
    setIsModalOpen(true);
  };
  const handleIdentityCorrectionSubmit = (firstName, lastName, firstNameFound, lastNameFound) => {
    setIsModalOpen(false);
    const updatedPatient = {
      ...patient,
      first_name: firstName,
      last_name: lastName,
      birth_name: patient.identical_birthname ? lastName : patient.birth_name
    };
    dispatch(updatePatient(updatedPatient));

    const newVerification = { firstNameFound, lastNameFound };
    dispatch(updateVerification(newVerification));
};

  const handlePreviewClick = () => {
    if (prescription) {
      const modalTitle = `Votre ordonnance : ${prescription.prescription_path.split("/").pop()}`;
      const url = api_route.files.fileUrl(prescription.prescription_path, patient.id);
      showImage(modalTitle, url, 'ordonnance');
    }
  };

  const PrescriptionImage = () => (
    <PreviewCard
      iconPath={prescriptionPicto}
      title="Ordonnance"
      imagePath={api_route.files.fileUrl(prescription.prescription_path, patient.id)}
      onPreviewClick={handlePreviewClick}
    />
  );

  const _renderFileValidation = () => {
    const isValid = prescription && prescription.valid
    const isPartiallyValid = isValid && verification && (!verification.firstNameFound || !verification.lastNameFound);

    return (
      <>
        {prescription && !prescription.valid ? (
          <div className={`${styles.gridContainer} ${styles.noMargin}`}>
            <div className={styles.thirdWidthColumn}>
              <div className={styles.imageContainer}>
                <PrescriptionImage />
              </div>
            </div>
            <div className={styles.twoThirdWidthColumn}>
              <div className={styles.alertContainer}>
                <Alert 
                  type="error" 
                  message="Votre ordonnance nécessite des vérifications."
                />
              </div>
              <LargeWhiteCard
                desktopWidth={100}
              >
                <div className={styles.nonConformityMessage}>
                  <p>Aucune information concernant votre identité et vos analyses n’est détectée dans votre ordonnance.</p>
                  <br />
                  <p>Pour nous aider à mieux traiter votre demande, merci de nous soumettre un autre fichier plus lisible (image nette et bien éclairée).</p>
                  <Button
                    onClick={() => {
                      removeStoredFile("prescription");
                      triggerFileSelect();
                    }}
                    text="MODIFIER"
                    className="primary"
                  />
                </div>
              </LargeWhiteCard>
            </div>
          </div>
        ) : (   
          <div className={`${styles.gridContainer} ${styles.noMargin}`}>
            <div className={styles.thirdWidthColumn}>
              <div className={styles.imageContainer}>
                <PrescriptionImage />
              </div>
            </div>
            <div className={styles.twoThirdWidthColumn}>
              <div className={styles.alertContainer}>
                <Alert 
                  type="error" 
                  message="Votre ordonnance nécessite des vérifications."
                />
              </div>
              <LargeWhiteCard
                desktopWidth={100}
              >
                <div className={styles.nonConformityMessage}>
                  <h5 className="mb-2">Identité du patient</h5>
                  {
                    isPartiallyValid ? (
                      <>
                        <p>
                          Les prénom(s) / nom(s) <strong className={styles.redText}>{patient.first_name} {patient.last_name} n'ont pas pu être détectés sur votre ordonnance</strong>. <br />
                          En l'absence de correction ou de modification de votre part, votre identité sera vérifiée directement au laboratoire.
                        </p>
                        <br />
                          <div className={styles.buttonsContainer}>
                            <Button
                              onClick={handleIdentityCorrectionClick}
                              text="Corriger mon identité"
                              className="btn-icon-text"
                              picto={<EditIcon fontSize="large" />} 
                            />

                            <Button
                              onClick={() => {
                                removeStoredFile("prescription");
                                triggerFileSelect();
                              }}
                              text="Modifier l'ordonnance"
                              className="btn-icon-text"
                              picto={<FileUploadIcon fontSize="large" />} 
                            />
                          </div>
                      </>
                    ) :(
                      <p className="mb-2">
                        <CheckCircleIcon style={{ marginRight: '10px', transform: "scale(1.5)", color: "var(--success)" }} />
                        Les prénom(s) / nom(s) <strong> {patient.first_name} {patient.last_name}</strong> sont bien détectés sur votre ordonnance.
                      </p>
                    )
                  }
                  
                </div>
              </LargeWhiteCard>
              <LargeWhiteCard
                desktopWidth={100}
                leftColumnWidth={100}
                justifyContent="left"
              >
                <div className={styles.nonConformityMessage}>
                  <h5 className="mb-2">Informations ordonnance</h5>
                  <p>Merci de corriger les informations suivantes :</p>
                  <PartialPrescriptionForm 
                    prescription={prescription} 
                    prescriptors_found={prescription.prescriptors_found}
                    verification={verification} 
                    removeStoredFile={removeStoredFile}
                    triggerFileSelect={triggerFileSelect}
                  />
                </div>
              </LargeWhiteCard>
            </div>
          </div>
        )}
      </>
    );
  }; 

  const _renderPreview = () => {
    return (
      <>
        {isPdf ? (
          <div className={styles.pdfPreview} >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={previewImage} />
            </Worker>
          </div>
        ) : (
          <img
            className={styles.imagePreview}
            src={previewImage}
            alt="Aperçu"
          />
        )}
        <div className={styles.largeButtonsDiv}>
          <Button
            type="button"
            onClick={() => {
              clearImage();
              triggerFileSelect();
            }}
            text="CHANGER D'ORDONNANCE"
            className="buttonPrimaire"
          />
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="button"
            onClick={onSubmit}
            text="VALIDER MON ORDONNANCE"
            className="primary"
            isLoading={loading}
          />
        </div>
      </>
    );
  };
  const _renderSelect = () => {
    return (
      <LargeWhiteCard
        image={prescriptionPicto}
        desktopWidth={70}
      >
        <p>
          <br />
          Pour un traitement optimal de votre ordonnance, merci de nous fournir {" "}
          <strong>une image lisible et bien cadrée de votre ordonnance</strong>{". "}
          Le service ne prend en charge qu'
          <strong>une seule ordonnance</strong>.
        </p>
        <br />
        <span className={styles.greyNotice}>
          Formats autorisés : jpeg, jpg, png ou pdf
          <Tooltip
            title="Poids maximum autorisé : 5 Mo"
            classes={{ tooltip: styles.customTooltip }}
          >
            <InfoIcon
              style={{
                marginLeft: '8px',
                fontSize: '2.5rem',
                borderRadius: '50%',
                color: 'black',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </span>
        <br />
        <br />
        <br />
        <br />
        <Button
          type="button"
          onClick={triggerFileSelect}
          picto={<DownloadIcon  style={{ fontSize: '3rem', marginRight: "1rem" }} />}
          text="TELECHARGER MON ORDONNANCE"
          className="primary telechargerButton"
        />
      </LargeWhiteCard>
    );
  };
  return (
    <div className={cx(styles.formContainerColumn, "alignCenter")}>
      <form>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={(e) => { handleFileChange(e) }}
        />
      </form>

      {renderStep()}
      <PartialIdentityForm 
        patient={patient} 
        onSubmit={handleIdentityCorrectionSubmit} 
        submitButtonText="Enregistrer" 
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}   
        verificationData={verification} 
      />
    </div>
  );
};

export default PrescriptionForm;

