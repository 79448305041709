import React from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentCard.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';

const DocumentCard = ({ fileName, isValid, onDelete }) => {
  return (
    <div className={styles.documentCard}>
      <div className={styles.statusIndicator} style={{ backgroundColor: isValid ? 'green' : 'red' }}></div>
      <p className={styles.fileName}>{fileName}</p>
      <DeleteIcon className={styles.deleteIcon} onClick={onDelete} />
    </div>
  );
};

DocumentCard.propTypes = {
  fileName: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DocumentCard;
