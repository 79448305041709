import React from 'react';
// Styles
import styles from './LayoutBackground.module.scss';
// Images
import { desktopBackgroundThreeShapes, mobileBackgroundThreeShapes, desktopBackgroundShaped } from "#images";
// Utils
import useIsDesktop from '#utils/useInDesktop'

const LayoutBackground = ({ children, pageType }) => {
  const isDesktop = useIsDesktop();

  let backgroundImage;
  if (pageType === "Home") {
    backgroundImage = isDesktop ? desktopBackgroundThreeShapes : mobileBackgroundThreeShapes;
  } else if (pageType === "Record") {
    backgroundImage = isDesktop ? desktopBackgroundShaped : "none";
  }

  const style = {
    backgroundImage: `url(${backgroundImage})`,
  };

  if (backgroundImage === "none") {
    delete style.backgroundImage;
  }

  return (
    <div className={styles.layoutBackground} style={style}>
      {children}
    </div>
  );
};

export default LayoutBackground;
