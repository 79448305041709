import React from 'react'
//Styles
import styles from './NavMobile.module.scss'
//Images
import { IconButton } from '@mui/material'
import BrandLogoPath from '../../assets/images/unilabs-logo-horizontal-color-text.svg'
// import LePassLogoPath from '../../assets/images/LePASS_logo.svg'

const NavMobile = () => {
  return (
    <div className={styles.appBar}>
      {/* <div className={styles.betaBannerMobile}>BETA</div> */}
      <div className={styles.leftContainer}>
        <a href='https://my-unilabs.fr/'>
          <div className={styles.brandLogoContainer}>
            <img src={BrandLogoPath} className={styles.logo} alt="Brand Logo Horizontal" />
          </div>
        </a>
      </div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        {/* <img src={LePassLogoPath} className={styles.lePassLogoNavMobile} alt="LePass Logo Nav" /> */}
        <header className={styles.header}>
          <h5>Le Pass</h5>
        </header>
      </IconButton>
    </div>
  )
}

export default NavMobile