import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

//Reducers
import { laboratorySelector, mutuelleSelector, patientSelector, prescriptionSelector } from "#reducers/selectors";
import { closeRecord } from "#reducers/recordReducer";
// Services
import { api_route } from "#services/api";
// Components
import { showImage } from '#utils/sweetAlert';
import Button from "#components/global/Button";
import Card from "#components/global/Card";
import NPSForm from './NpsForm';
import FormWrapper from './FormWrapper';
import SmallWhiteCard from '#components/global/SmallWhiteCard';
import LargeWhiteCard from '#components/global/LargeWhiteCard';
// Styles
import styles from "./Form.module.scss";
//Images
import { imageAJeunPath, imageDocPath, laboRecapPicto, documentsRecapPicto, analyseRecapPicto } from "#images";

const ConfirmationForm = () => {
  const dispatch = useDispatch()
  const laboratory = useSelector(laboratorySelector);
  const mutuelle = useSelector(mutuelleSelector);
  const patient = useSelector(patientSelector);
  const prescription = useSelector(prescriptionSelector)
  const biologicalAssessment = useSelector(state => state.sessions.biologicalAssessment);
  const [showNpsForm, setShowNpsForm] = useState(true);
  const registrationFinished = useSelector(state => state.sessions.registrationFinished)
  const title = registrationFinished ? (
    <h3 className={styles.h3Success}>{""}</h3>
  ) : (
    <h3>Récapitulatif de vos informations</h3>
  );

  function toTitleCase(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const handleValidationClick = () => {
    dispatch(closeRecord())
  };

  // Fonction pour afficher la modal de la mutuelle
  const handleMutuellePreviewClick = (event) => {
    event.preventDefault();
    if (mutuelle) {
      const fileName = mutuelle.mutuelle_path.split("/").pop();
      const modalTitle = `Votre carte mutuelle : ${fileName}`;
      const url = api_route.files.fileUrl(mutuelle.mutuelle_path, patient.id)
      showImage(modalTitle, url, 'carte mutuelle')
    }
  };

  // Fonction pour afficher la modal de la prescription
  const handlePrescriptionPreviewClick = (event) => {
    event.preventDefault();
    if (prescription) {
      const fileName = prescription.prescription_path.split("/").pop();
      const modalTitle = `Votre ordonnance : ${fileName}`;
      const url = api_route.files.fileUrl(prescription.prescription_path, patient.id)
      showImage(modalTitle, url, 'ordonnance')
    }
  };

  return (
    <FormWrapper title={title}>
      <>
        <div className={styles.alignCenter}>
          <div className={styles.pContent}>
          
          {/* RECAP DES INFOS : */}
            {!registrationFinished && (
              <>
                <div className={styles.flexContainer}>
                  
                  <SmallWhiteCard 
                    title= "Votre Laboratoire"
                    image={laboRecapPicto}
                  >
                    <h5>{laboratory.name}</h5>
                    <p>{toTitleCase(laboratory.address)}</p>
                    <p>{`${laboratory.zip} ${toTitleCase(laboratory.city)}`}</p>
                  </SmallWhiteCard>

                  {((mutuelle && !mutuelle.empty) || (prescription && !prescription.empty)) && (
                    <SmallWhiteCard 
                      title= "Vos Documents"
                      image={documentsRecapPicto}
                    >
                      <div className={styles.recapDocuments}>
                        {mutuelle && !mutuelle.empty && (
                          <p>
                            <strong>Mutuelle</strong><br />
                            <a href="#" onClick={handleMutuellePreviewClick}>voir ici</a>
                          </p>
                        )}
                        {prescription && !prescription.empty && (
                          <p>
                            <strong>Ordonnance</strong><br />
                            <a href="#" onClick={handlePrescriptionPreviewClick}>voir ici</a>
                          </p>
                        )}
                      </div>
                    </SmallWhiteCard>
                  )}

                  {biologicalAssessment && (
                    <SmallWhiteCard 
                      title= "Votre analyse"
                      image={analyseRecapPicto}
                    >
                    <p><strong>{biologicalAssessment.label}</strong><br />sans ordonnance</p>
                    </SmallWhiteCard>
                  )}

                </div>
                <div className="single-btn-container btn-center">
                  <Button
                    className="primary"
                    text="VALIDER MON ENREGISTREMENT"
                    onClick={handleValidationClick}
                  />
                </div>
              </>
            )}
          </div>

          {/* ENREGISTREMENT CONFIRME : */}
          {registrationFinished && (
            <div className={styles.flexContainer}>

              <LargeWhiteCard
                desktopWidth={70} 
              >              
                <div className={styles.alignCenter}>
                  <h3 style={{color: "var(--primary)"}}> Votre enregistrement en ligne est confirmé. </h3>
                  <br />
                  <p className='mb-3'> 
                    Vous allez recevoir un{" "}
                    <strong>email de confirmation contenant un QR code</strong>. 
                    <br />Venez muni de votre QR code au laboratoire !
                  </p>

                  {showNpsForm ? (
                    <NPSForm setShowNpsForm={setShowNpsForm} />
                  ) : (
                    <div className={styles.cardsContainer}>
                      <Card
                        link="https://my-unilabs.fr/preparer-ma-visite/a-jeun-pas-a-jeun"
                        image={imageAJeunPath}
                        altText="À jeun ? Pas à jeun ?"
                        title={<span>À jeun ?<br />Pas à jeun ?</span>}
                      />
                      <Card
                        link="https://my-unilabs.fr/preparer-ma-visite/documents-a-apporter-le-jour-de-l-examen"
                        image={imageDocPath}
                        altText="Documents à apporter le jour de l'examen"
                        title="Documents à apporter le jour de l'examen"
                      />
                    </div>
                  )}
                </div>
              </LargeWhiteCard>

            </div>
          )}
        </div>
      </>
    </FormWrapper>
  );
};

export default ConfirmationForm;
