import React from 'react';
import cx from 'classix'
//Styles
import styles from './Nav.module.scss'

const NavState = ({object, defaultLabel, icon, activeIcon, children, topSeparator, bottomSeparator, specificFieldCheck = true}) => {
  const hasInfo = object && Object.keys(object).length > 0 && specificFieldCheck;
  return (
    <>
      <div className={cx(styles.navStateContainer, hasInfo && styles.navActiveInfo)}>
        {hasInfo ? (
          <>
            {activeIcon || icon}
            <div className={styles.navInfos}>
              {children}
            </div>
          </>
        ):(
          <>
            {icon}
            <div className={styles.navInfos}>
            <p>{defaultLabel}</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NavState;