import { useSelector } from 'react-redux';
//Images
import { horizontalLogoPath, locationOffIcon, locationOnIcon, identityOffIcon, identityOnIcon, mutuelleOffIcon, mutuelleOnIcon, prescriptionOffIcon, prescriptionOnIcon, biologicalAssessmentOffIcon, biologicalAssessmentOnIcon} from "#images";
//Components
import NavState from "./NavState";
// Selectors
import { laboratorySelector, patientSelector, mutuelleSelector, prescriptionSelector } from "#reducers/selectors"
//Styles
import { Drawer, Box } from "@mui/material";
import styles from "./Nav.module.scss";
const Nav = () => {
  const laboratory  = useSelector(laboratorySelector)
  const patient  = useSelector(patientSelector)
  const mutuelle  = useSelector(mutuelleSelector)
  const prescription  = useSelector(prescriptionSelector)
  const parcours = useSelector(state => state.sessions.parcours);
  const biologicalAssessment = useSelector(state => state.sessions.biologicalAssessment);

  return (
    <Drawer
      className={styles.drawer}
      classes={{ paper: styles.drawerPaper }}
      variant={"permanent"}
      anchor="left"
      PaperProps={{ elevation: 1 }}
    >
      <a href='https://my-unilabs.fr/' >
        <div className={styles.container}>
          <img
            src={horizontalLogoPath}
            className={styles.logo}
            alt="Brand Logo Horizontal"
          />
        </div>
      </a>
      <div className={styles.navStatParent}>
        {parcours === 'without_prescription' && (
          <NavState
            defaultLabel="Votre analyse"
            object={biologicalAssessment}
            icon={<img src={biologicalAssessmentOffIcon} className={styles.iconState} alt='biologicalAssessment icon' />}
            activeIcon={<img src={biologicalAssessmentOnIcon} className={styles.iconState} alt='biologicalAssessment icon' />}

          >
            {biologicalAssessment && <p>{biologicalAssessment.label}</p>}
          </NavState>
        )}
        <NavState
          defaultLabel="Laboratoire"
          object={laboratory}
          icon={<img src={locationOffIcon} className={styles.iconState} alt='laboratory icon' />}
          activeIcon={<img src={locationOnIcon} className={styles.iconState} alt='laboratory icon' />}
          >
          {laboratory && (
            <>
              <p className={styles.laboratoryName}>{laboratory.name}</p>
              <p>{laboratory.address}</p>
              <p>{`${laboratory.zip} ${laboratory.city}`}</p>
            </>
          )}
        </NavState>
        <NavState
          defaultLabel="Identité"
          object={patient}
          icon={<img src={identityOffIcon} className={styles.iconState} alt='identity icon' />}
          activeIcon={<img src={identityOnIcon} className={styles.iconState} alt='identity icon' />}
          specificFieldCheck={patient && patient.last_name && patient.first_name }
        >
          {patient && (
            <>
              <p>{patient.first_name+" "+patient.last_name}</p>
            </>
          )}
        </NavState>
        {parcours === 'with_prescription' && (
          <>
            <NavState
              defaultLabel="Votre ordonnance"
              object={prescription}
              icon={<img src={prescriptionOffIcon} className={styles.iconState} alt='Prescription icon' />}
              activeIcon={<img src={prescriptionOnIcon} className={styles.iconState} alt='Prescription icon' />}
            >
              {prescription && <p>{prescription.file_name}</p>}
            </NavState>
            <NavState
              defaultLabel="Votre prise en charge"
              object={mutuelle}
              icon={<img src={mutuelleOffIcon} className={styles.iconState} alt='mutuelle icon' />}
              activeIcon={<img src={mutuelleOnIcon} className={styles.iconState} alt='mutuelle icon' />}
            >
              {mutuelle && <p>{mutuelle.file_name}</p>}
            </NavState>
          </>
        )}
      </div>
      <div style={{ flex: 1, display: 'flex' }}>
        <Box
          sx={{
            flex: 1,
            // backgroundImage: `url(${uLogoPath})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </div>
    </Drawer>
  );
};

export default Nav;
