import React from 'react'

import Nav from '#components/nav/Nav'
import NavMobile from '#components/nav/NavMobile'
import Footer from '#components/footer/Footer'

import styles from './LayoutBase.module.scss'
//Utils
import useIsDesktop from '#utils/useInDesktop'

const LayoutBase = ({ children }) => {
  const isDesktop = useIsDesktop();
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {
          isDesktop ? (
            <Nav />
          ):(
            <NavMobile/>
          ) 
        }
        <div className={styles.contentContainer}>
          <div className="content-column">
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  )
}

export default LayoutBase