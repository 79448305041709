import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from '#utils/sweetAlert';
// Actions
import { removeMutuelle, removePrescription } from '#reducers/fileReducer';

export const useFileHandling = (fileInputId) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isPdf, setIsPdf] = useState(null);
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();

  const ALLOWED_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 5242880) {
        showToast('error', 'La taille du fichier ne doit pas dépasser 5 Mo.');
        clearImage();
        return;
      }

      if (!ALLOWED_TYPES.includes(file.type)) {
        showToast('error', 'Seuls les fichiers JPEG, JPG, PNG, PDF sont autorisés.');
        clearImage();
        return;
      }

      setFile(file);
      setFileName(file.name);
      setIsPdf(file.type === 'application/pdf');
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileSelect = () => {
    const fileInput = document.getElementById(fileInputId);
    setPreviewImage(null);
    fileInput.click();
  };

  const clearImage = () => {
    const fileInput = document.getElementById(fileInputId);
    fileInput.value = ""; // Réinitialiser l'input
  };

  const removeStoredFile = (type, id = null) => {
    clearImage();
    switch (type) {
      case 'mutuelle':
        dispatch(removeMutuelle(id));
        break;
      case 'prescription':
        dispatch(removePrescription());
        break;
      default:
        // TODO
        break;
    }
  };

  return { file, fileName, isPdf, previewImage, handleFileChange, triggerFileSelect, clearImage, removeStoredFile };
};
