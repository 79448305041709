import { combineReducers } from 'redux';
import laboratoryReducer from './laboratoryReducer';
import biologicalReducer from './biologicalReducer';
import sessionReducer from './sessionReducer';
import recordReducer from './recordReducer';
import fileReducer from './fileReducer';

const rootReducer = combineReducers({
  laboratories: laboratoryReducer,
  biological: biologicalReducer,
  sessions: sessionReducer,
  records: recordReducer,
  files: fileReducer
});

export default rootReducer;