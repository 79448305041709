import { put, call } from 'redux-saga/effects';
import { api_route, createApi } from '#services/api';
import { showToast } from '#utils/sweetAlert';
import { setConfigSteps, setBiologicalAssessments } from '#reducers/sessionReducer';
// Saga
import { createRecord } from '#sagas/recordSaga';
import { getLaboratories } from '#sagas/laboratoriesSaga';
import { getBiologicals } from '#sagas/biologicalSaga';

export const getSteps = function* getSteps() {
  const api = createApi();
  const response = yield call(api.get, api_route.configs.steps);
  if (response.ok && response.data && response.data.steps) {
    yield put(setConfigSteps(response.data.steps));
  } else {
    throw 'Impossible de charger les étapes';
  }
};

export const getBiologicalAssessments = function* getBiologicalAssessments() {
  const api = createApi();
  const response = yield call(api.get, api_route.configs.biological_assessments);
  if (response.ok && response.data && response.data.biological_assessments) {
    yield put(setBiologicalAssessments(response.data.biological_assessments));
  } else {
    throw 'Impossible de charger les bilans sans ordonnance'
  }
}

export const initApp = function* initApp() {
  try {
    yield call(getLaboratories);
    yield call(createRecord);
    yield call(getSteps);
    yield call(getBiologicals);
    yield call(getBiologicalAssessments);
  } catch (error) {
    console.error(error);
    showToast('error', 'Impossible de charger la configuration');
  }
};