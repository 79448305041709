import React, { useState } from 'react';
import Select from 'react-select';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Reducers
import { setLaboratory } from '#reducers/laboratoryReducer';
import { nextStep } from "#reducers/sessionReducer.js";
// Selectors
import { laboratoriesSelector, laboratorySelector } from "#reducers/selectors";
// Components
import { labInputIconPath } from "#images";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
//Styles
import styles from './Form.module.scss';
import cx from 'classix';

const LaboratoryItem = ({ code, name, address, zip, city, onClick, selected }) => {
  return (
    <div className={ cx(styles.LaboratoryItemContainer, selected && styles.selected) } onClick={() => onClick(code)}>
      <div className={styles.LaboratoryItemAddress}>
        <h5>{name}</h5>
        <p className={styles.pContent}>
          {address}, {`${zip} ${city}`}
        </p>
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

const LaboratoryForm = () => {
  const dispatch = useDispatch();
  const [zipSelected, setZipSelected] = useState([]);
  const selectedLaboratory = useSelector(laboratorySelector);
  const laboratories = useSelector(laboratoriesSelector);

  const zipOptions = Array.from(new Set(laboratories.map(lab => lab.zip))).map(zip => ({
    value: zip,
    label: zip
  }));

  const handleZipChange = (selectedOptions) => {
    const newSelectedOptions = selectedOptions.map(el => el.value);
    setZipSelected(newSelectedOptions);
  };

  const selectLaboratory = (code) => {
    const laboratory = laboratories.find(laboratory => laboratory.code === code);
    dispatch(setLaboratory(laboratory));
    dispatch(nextStep());
  };

  return (
    <div className={`${styles.formContainerColumn} ${(zipSelected.length === 0 && !selectedLaboratory) ? styles.formContainerColumnCentered : ''}`}>
      <div className={styles.halfWidth}>
        <div className={styles.labSelectContainer}>
          <img src={labInputIconPath} alt="Laboratory select icon" />
          <Select
            isMulti
            options={zipOptions}
            onChange={handleZipChange}
            isClearable
            placeholder="Sélectionnez un code postal"
            className={styles.reactSelectContainer}
            styles={customStyles}
          />
        </div>
        {laboratories.filter(lab => 
          zipSelected.length > 0 ? zipSelected.includes(lab.zip) : (selectedLaboratory ? lab.code === selectedLaboratory.code : false)
        ).map((lab) => (
          <LaboratoryItem 
            key={lab.code} 
            onClick={selectLaboratory} 
            selected={selectedLaboratory && lab.code === selectedLaboratory.code}
            {...lab} 
          />
        ))}
      </div>
    </div>
  );
};

export default LaboratoryForm;

const customStyles = {
  control: (base, state) => ({
    ...base,
    height: '10rem',
    minHeight: '10rem',
    borderColor: 'transparent',
    borderRadius: '2rem',
    boxShadow: 'none',
    fontSize: '1.7rem',
    '&:hover': {
      borderColor: 'transparent'
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#C4C4C4',
    fontSize: '1.7rem'
  }),
  valueContainer: (base) => ({
    ...base,
    marginLeft: '10rem',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'rgba(229, 48, 18, 0.25)' : base.backgroundColor,
    fontSize: '2rem',
    fontWeight: state.isFocused ? '600' : '400'
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'rgba(229, 48, 18, 0.25)'
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '2rem',
    fontWeight: 'lighter'
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {
      backgroundColor: '#FF671F',
      color: 'white'
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#000'
  }),
};
