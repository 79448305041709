import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import cx from "classix";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { uploadMutuelle, uploadPatientDocument, removePatientDocument } from "#reducers/fileReducer";
import { mutuelleSelector, patientSelector, documentTypesSelector, patientDocumentsSelector } from "#reducers/selectors";
import { nextStep, setDocumentSelectionStep } from '#reducers/sessionReducer';
import Button from "#components/global/Button";
import Alert from '#components/global/Alert';
import LargeWhiteCard from '#components/global/LargeWhiteCard';
import DocumentCard from '#components/global/DocumentCard';
import { useFileHandling } from "#utils/useFileHandling";
import { api_route } from "#services/api";
import styles from "./Form.module.scss";
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const MutuelleForm = () => {
  const dispatch = useDispatch();
  const mutuelle = useSelector(mutuelleSelector);
  const patient = useSelector(patientSelector);
  const documentTypes = useSelector(documentTypesSelector);
  const patientDocuments = useSelector(patientDocumentsSelector);
  const loading = useSelector((state) => state.files.loading);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [step, setStep] = useState("select");
  const [documentType, setDocumentType] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const {
    file,
    fileName: mutuelleFileName,
    previewImage,
    handleFileChange,
    triggerFileSelect,
    clearImage,
    removeStoredFile,
    isPdf
  } = useFileHandling("fileInput");

  useEffect(() => {
    const isSaveEnabled = (mutuelle && mutuelle.valid) || patientDocuments.length > 0 || checkboxChecked;
    setIsSaveDisabled(!isSaveEnabled);
  }, [mutuelle, patientDocuments, checkboxChecked]);

  useEffect(() => {
    if (file) {
      uploadMutuellefile();
    }
  }, [file]);

  useEffect(() => {
    dispatch(setDocumentSelectionStep(step === "documentSelection"));
    return () => dispatch(setDocumentSelectionStep(false));
  }, [step, dispatch]);

  const onSubmit = () => {
    if(isSaveDisabled) return;
    dispatch(nextStep());
  };

  const onDeleteAdditionalDocument = (documentId) => {
    dispatch(removePatientDocument(documentId));
  };
  

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const defineStep = () => {
    if (step === "preview") {
      return "preview";
    } else if (step === "validation") {
      return "validation";
    } else if (step === "documentSelection") {
      return "documentSelection";
    } else {
      return "select";
    }
  };

  const renderStep = () => {
    const step = defineStep();
    switch (step) {
      case "select":
        return _renderSelect();
      case "preview":
        return _renderPreview();
      case "validation":
        return _renderFileValidation();
      case "documentSelection":
        return _renderDocumentSelection();
      default:
        return null;
    }
  };

  const MutuelleImage = () => (
    mutuelle ? (
      <img
        className={styles.imagePreview}
        src={api_route.files.fileUrl(mutuelle.mutuelle_path, patient.id)}
        alt="Aperçu"
      />
    ) : null
  );

  const MutuelleInfoItem = ({ found, label }) => (
    <li>
      {`${label} : `}
      <span className={found ? styles.infoLue : styles.infoNonLue}>
        {found ? "information lue" : "information non lue"}
      </span>
    </li>
  );

  const uploadMutuellefile = async () => {
    if (file) {
      setIsAnalyzing(true); // Commence l'analyse du fichier
      try {
        await dispatch(uploadMutuelle(file)); // Attend la fin de l'upload
        const isValid = mutuelle && mutuelle.valid;
        setStep(isValid ? "select" : "validation"); // Définit l'étape en fonction de la validité
      } catch (error) {
        console.error('Erreur lors de l\'upload de la mutuelle', error);
        setStep("validation"); // En cas d'erreur, affiche la vue de validation
      } finally {
        setIsAnalyzing(false); // Qu'il y ait une erreur ou un succès, arrêter l'état d'analyse
      }
    }
  };  

  const handleAdditionalFileChange = (event) => {
    const newFile = event.target.files[0];
    dispatch(uploadPatientDocument(newFile, documentType, newFile.name));
    setStep("select");
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const _renderFileValidation = () => {
    const isValid = mutuelle && mutuelle.valid;
    return (
      <>
        {mutuelle && !isValid ? (
          <div className={`${styles.gridContainer} m-0`}>
            <div className={styles.thirdWidthColumn}>
              <div className={styles.imageContainer}>
                {isPdf ? (
                  previewImage && (
                    <div className={styles.pdfPreview} >
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={previewImage} />
                      </Worker>
                    </div>
                  )
                ) : (
                  <img
                    className={styles.imagePreview}
                    src={previewImage}
                    alt="Aperçu"
                  />
                )}
              </div>
            </div>
            <div className={styles.twoThirdWidthColumn}>
              {!isValid && (
                <>
                  <div className={styles.alertContainer}>
                    <Alert 
                      type="error" 
                      message="Votre carte mutuelle n'est pas conforme."
                    />
                  </div>
                  <LargeWhiteCard desktopWidth={100}>
                    <div className={styles.nonConformityMessage}>
                      <p>Certaines informations clés n'ont pas pu être détectées sur votre carte mutuelle :</p>
                      <ul>
                        <MutuelleInfoItem found={mutuelle.mutuelle_end_validity_found} label="Date de fin de validité" />
                        <MutuelleInfoItem found={mutuelle.security_number_found} label="Numéro de sécurité sociale" />
                        <MutuelleInfoItem found={mutuelle.teletransmission_number_found} label="Numéro AMC ou télétransmission" />
                      </ul>
                      <p>Pour nous aider à mieux traiter votre demande, merci de nous soumettre un autre fichier plus lisible (image nette et bien éclairée).</p>
                      <div className={styles.buttonsDiv}>
                        <Button
                          onClick={() => {
                            removeStoredFile("mutuelle", mutuelle.id);
                            triggerFileSelect();
                            setStep("select");
                          }}
                          text="CHANGER DE CARTE "
                          className="primary"
                        />
                      </div>
                    </div>
                  </LargeWhiteCard>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.imageContainer}>
            <div className={styles.alertContainer}>
              {isAnalyzing && (
                <Alert 
                  type="info" 
                  message="Analyse de votre carte en cours..."
                  desktopWidth={90}
                />
              )}
              {!isAnalyzing && mutuelle && mutuelle.valid && (
                <Alert 
                  type="success" 
                  message="Votre carte mutuelle est conforme."
                  desktopWidth={90}
                />
              )}
            </div>
            <MutuelleImage />
            <div className="single-btn-container btn-center">
              <Button
                onClick={() => {
                  removeStoredFile("mutuelle", mutuelle.id);
                  triggerFileSelect();
                }}
                text="CHANGER DE CARTE"
                className="buttonPrimaire"
              />
              <span style={{ margin: "0 10px" }}></span>
              <Button
                type="button"
                onClick={() => { setStep("select"); }}
                text="VALIDER MA CARTE"
                className="primary"
                isLoading={loading || isAnalyzing}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const _renderPreview = () => {
    return (
      <>
        {isPdf ? (
          previewImage && (
            <div className={styles.pdfPreview}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={previewImage} />
              </Worker>
            </div>
          )
        ) : (
          <img
            className={styles.imagePreview}
            src={previewImage}
            alt="Aperçu"
          />
        )}
        <div className={styles.largeButtonsDiv}>
          <Button
            type="button"
            onClick={() => {
              clearImage();
              triggerFileSelect();
              setStep("select");
            }}
            text="CHANGER DE CARTE"
            className="buttonPrimaire"
          />
          <span style={{ margin: "0 10px" }}></span>
          <Button
            type="button"
            onClick={uploadMutuellefile}
            text="VALIDER MA CARTE"
            className="primary"
            isLoading={loading}
          />
        </div>
      </>
    );
  };
  

  const _renderDocumentSelection = () => {
    return (
      <LargeWhiteCard desktopWidth={70}>
        <p>Sélectionnez le type de prise en charge :</p>
        <select
          className={styles.customSelect}
          value={documentType}
          onChange={handleDocumentTypeChange}
        >
          <option value="">-- Sélectionnez un type de prise en charge --</option>
          {documentTypes.map((doc) => (
            <option key={doc.id} value={doc.code}>
              {doc.label}
            </option>
          ))}
        </select>
        <br />
        <br />
        <div className={styles.addDocumentsDiv}>
          <Button
            type="button"
            onClick={() => setStep("select")}
            text="Annuler"
            className="secondary"
          />
          <Button
            type="button"
            onClick={() => document.getElementById('additionalFileInput').click()}
            text="Télécharger"
            className="primary"
          />
          <input
            type="file"
            id="additionalFileInput"
            style={{ display: "none" }}
            onChange={(e) => handleAdditionalFileChange(e)}
          />
        </div>
      </LargeWhiteCard>
    );
  };
    
  const _renderSelect = () => {
    return (
      <>
        <LargeWhiteCard desktopWidth={70}>
          <p>
            Pour éviter d’avancer des frais, veuillez ajouter votre document de prise en charge :
          </p>
          <br />
          <span className={styles.greyNotice}>
            Formats autorisés : jpeg, jpg, png ou pdf
            <Tooltip
              title="Poids maximum autorisé : 5 Mo"
              classes={{ tooltip: styles.customTooltip }}
            >
              <InfoIcon
                style={{
                  marginLeft: '8px',
                  fontSize: '2.5rem',
                  borderRadius: '50%',
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </span>
          <br />
          <br />
          <br />
          <div className={styles.addDocumentsDiv}>
            <div className={styles.documentsCategory}>
              <p>
                <strong className={styles.redText}>
                  Votre carte de mutuelle
                </strong>
              </p>
            </div>
            <div className={styles.documentButtonDiv}>     
              <Button
                type="button"
                onClick={() => {
                  triggerFileSelect();
                  setStep("select");
                }}
                picto={<DownloadIcon style={{ fontSize: '3rem', marginRight: "1rem" }} />}
                text="AJOUTER MA CARTE"
                className="primary"
                style={{ width: "350px" }}
              />
            </div>
          </div>
          {(mutuelle && (mutuelleFileName || mutuelle.file_name)) && (
            <DocumentCard
              fileName={mutuelleFileName || mutuelle.file_name}
              isValid={mutuelle ? mutuelle.valid : false}
              onDelete={() => {
                removeStoredFile('mutuelle', mutuelle.id);
                clearImage();
              }}
            />
          )}
          <div className={styles.addDocumentsDiv}>
            <div className={styles.documentsCategory}>
              <div>
                <p>
                  <strong className={styles.redText}>
                    Autre document de prise en charge
                  </strong>
                </p>
                <p className={cx(styles.redText, styles.italicText)}>
                  (CMU, 100% ALD, 100% infertilité, 100% invalidité, Accident de travail)
                </p>
              </div>
            </div>
            <div className={styles.documentButtonDiv}>
              <Button
                type="button"
                onClick={() => setStep("documentSelection")}
                picto={<DownloadIcon style={{ fontSize: '3rem', marginRight: "1rem" }} />}
                text="AJOUTER UN DOCUMENT"
                className="primary"
                style={{ width: "350px" }}
              />
            </div>
          </div>
          <br />
          {patientDocuments.map((doc) => (
            <>
            <DocumentCard
              key={doc.id}
              fileName={doc.file_name || doc.file?.name}
              isValid={true}
              onDelete={() => onDeleteAdditionalDocument(doc.id)}
            />
            </>
          ))}
          {!((mutuelle && mutuelle.valid) || patientDocuments.length > 0) && (
            <div className={styles.largeButtonsDiv}>
              <label className={styles.checkboxContainer}>
                <input type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} />
                <span className={styles.regularNotice}>
                  Je n'ai pas de complémentaire ou je transmettrai mes documents lors de ma venue au laboratoire. Je reconnais que je pourrais être tenu de régler des frais.
                </span>
              </label>
            </div>
          )}
        </LargeWhiteCard>
        <Button
          type="button"
          text="ENREGISTRER"
          className="primary"
          isDisabled={isSaveDisabled}
          onClick={onSubmit}
        />
      </>
    );
  };

  return (
    <form className={cx(styles.formContainerColumn, "alignCenter")}>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={(e) => handleFileChange(e)}
      />
      <input
        type="file"
        id="additionalFileInput"
        style={{ display: "none" }}
        onChange={(e) => handleAdditionalFileChange(e)}
      />
      {renderStep()}
    </form>
  );
};

export default MutuelleForm;
