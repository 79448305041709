import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Home, Record } from "#pages";
//Styles
import { ThemeProvider } from '@mui/material'
import { lightTheme } from './theme/themes'


function App() {
  const isContentAccessible = useSelector(state => state.sessions.cgu)
  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/record" element={isContentAccessible ? <Record /> : <Navigate to="/home" />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={isContentAccessible ? <Navigate to="/record" /> : <Navigate to="/home" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
