const initialState = {
  loading: false,
  mutuelle: null,
  prescription: null,
  patientDocuments: []
};

const UPLOAD_MUTUELLE = "UPLOAD_MUTUELLE";
const UPLOAD_PRESCRIPTION = "UPLOAD_PRESCRIPTION";
const SAVE_MUTUELLE = "SAVE_MUTUELLE";
const REMOVE_MUTUELLE = "REMOVE_MUTUELLE";
const SAVE_PRESCRIPTION = "SAVE_PRESCRIPTION";
const REMOVE_PRESCRIPTION = "REMOVE_PRESCRIPTION";
const UPDATE_PRESCRIPTION = 'UPDATE_PRESCRIPTION';
const UPLOAD_PATIENT_DOCUMENT = "UPLOAD_PATIENT_DOCUMENT";
const SAVE_PATIENT_DOCUMENT = "SAVE_PATIENT_DOCUMENT";
const REMOVE_PATIENT_DOCUMENT = "REMOVE_PATIENT_DOCUMENT";
const SET_ERROR = "SET_ERROR";

function fileReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_MUTUELLE:
    case UPLOAD_PRESCRIPTION:
    case UPLOAD_PATIENT_DOCUMENT:
      return { ...state, loading: true };
    case SAVE_MUTUELLE:
      return { ...state, loading: false, mutuelle: action.mutuelle };
    case REMOVE_MUTUELLE:
      return { ...state, loading: false, mutuelle: null };
    case SAVE_PRESCRIPTION:
      return { ...state, loading: false, prescription: action.prescription };
    case REMOVE_PRESCRIPTION:
      return { ...state, loading: false, prescription: null };
    case UPDATE_PRESCRIPTION:
      return { ...state, loading: false, prescription: { ...state.prescription, ...action.prescription } };
    case SAVE_PATIENT_DOCUMENT:
      return { ...state, loading: false, patientDocuments: [...state.patientDocuments, action.patientDocument] };
    case REMOVE_PATIENT_DOCUMENT:
      return { ...state, loading: false, patientDocuments: state.patientDocuments.filter(doc => doc.id !== action.patientDocumentId) };
    case SET_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default fileReducer;

export const uploadMutuelle = (file) => {
  return {
    type: UPLOAD_MUTUELLE,
    file,
  };
};

export const uploadPrescription = (file) => {
  return {
    type: UPLOAD_PRESCRIPTION,
    file,
  };
};

export const saveMutuelle = (mutuelle) => {
  return {
    type: SAVE_MUTUELLE,
    mutuelle
  };
};

export const removeMutuelle = (mutuelleId) => {
  return {
    type: REMOVE_MUTUELLE,
    mutuelleId
  };
};

export const savePrescription = (prescription) => {
  return {
    type: SAVE_PRESCRIPTION,
    prescription
  };
};

export const updatePrescription = (prescription) => {
  return {
    type: UPDATE_PRESCRIPTION,
    prescription
  };
};

export const removePrescription = () => {
  return {
    type: REMOVE_PRESCRIPTION
  };
};

export const uploadPatientDocument = (file, documentCode, fileName) => {
  return {
    type: UPLOAD_PATIENT_DOCUMENT,
    file,
    documentCode,
    fileName
  };
};

export const savePatientDocument = (patientDocument) => {
  return {
    type: SAVE_PATIENT_DOCUMENT,
    patientDocument
  };
};

export const removePatientDocument = (patientDocumentId) => {
  return {
    type: REMOVE_PATIENT_DOCUMENT,
    patientDocumentId
  };
};

export const setError = () => {
  return {
    type: SET_ERROR
  };
};

