import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const showToast = (icon, message) => {
  Toast.fire({
    icon: icon,
    title: message,
    customClass: {
      popup: 'my-custom-toast'
    }
  })
};

export const showImage = (title, url, altImg) => {
  const isMobile = window.innerWidth < 960;

  Swal.fire({
    title: title,
    imageUrl: url,
    imageAlt: altImg,
    showCloseButton: true,
    showConfirmButton: false,
    width: isMobile ? "90%" : "60%",
    maxHeight: "80%",
    height: "auto",
    padding: "1%",
    customClass: {
      popup: 'zoom-in',
    },
    showClass: {
      popup: 'zoom-in'
    },
    hideClass: {
      popup: 'zoom-out'
    },
  });
}

export const showCustomPopup = (title, contentHtml, patient, onSubmit, onClose) => {
  const isMobile = window.innerWidth < 960;
  Swal.fire({
    title: title,
    html: contentHtml,
    showCloseButton: true,
    confirmButtonColor: "#3085d6",
    showConfirmButton: false,
    maxHeight: "80%",
    height: "auto",
    padding: "1%",
    didOpen: () => {
      Swal.getPopup().querySelector('#identityForm').addEventListener('submit', (e) => {
        e.preventDefault();
        const firstName = Swal.getPopup().querySelector('#firstName').value;
        const lastName = Swal.getPopup().querySelector('#lastName').value;
        onSubmit(firstName, lastName);
        onClose();
      });
    }
  });
};
