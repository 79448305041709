import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import Cleave from "cleave.js/react";

const DateInput = React.forwardRef((props, ref) => {
    return (
        <Cleave
            {...props}
            inputMode="numeric"
            options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'], numericOnly: true  }}
            htmlRef={ref}
        />
    );
});

const DateField = ({ control, name, label, required, gridSize, sx  }) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <Grid item xs={12} md={gridSize || 6}>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                    <TextField
                        {...rest}
                        InputProps={{
                            inputComponent: DateInput,
                        }}
                        inputRef={ref}
                        label={label || ''}
                        error={Boolean(fieldState?.error)}
                        helperText={fieldState?.error?.message}
                        color="secondary"
                        required={required}
                        variant="outlined"
                        fullWidth
                        placeholder={isFocused ? "JJ/MM/AAAA" : ""}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        sx={sx}
                    />
                )}
            />
        </Grid>
    );
};

export default DateField;