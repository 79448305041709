// Card.js
import React from 'react';
import styles from './Card.module.scss';

function Card({ link, image, altText, title }) {
    return (
        <a href={link} className={styles.card}>
        <img src={image} alt={altText} />
        <div className={styles.cardBody}>
            <p>{title}</p>
        </div>
        </a>
    );
}

export default Card;
