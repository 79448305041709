// PreviewCard.js
import React from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import styles from './PreviewCard.module.scss';

const PreviewCard = ({ iconPath, title, imagePath, onPreviewClick }) => {
    return (
        <div className={styles.flexColumn}>
            <div className={styles.imagePreviewContainer} onClick={onPreviewClick} style={{ cursor: 'pointer'}}>
                <img
                    className={styles.imgPreview}
                    src={imagePath}
                    alt={`Aperçu de ${title.toLowerCase()}`}
                />
                <ZoomInIcon className={styles.zoomIcon} />
            </div>
        </div>
    );
};

export default PreviewCard;