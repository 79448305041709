// BiologicalAssessmentCard.js
import React from 'react';
import styles from './BiologicalAssessmentCard.module.scss';
import { imgBiologicalDefaultPicto } from "#images";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';

function BiologicalAssessmentCard({ title, content, onClick, color, isSelected }) {
    const cardStyle = {
        borderColor: isSelected ? color : "transparent",
        borderWidth: isSelected ? "3px" : "0",
        borderStyle: "solid"
    };

    return (
        <div className={styles.biologicalAssessmentCard} onClick={onClick} style={cardStyle}>
            <div className={styles.biologicalAssessmentCardTopContainer}>
                <div className={styles.biologicalAssessmentCardTitle}> Évaluation Biologique </div>
                <img src={imgBiologicalDefaultPicto} alt="biological" />
            </div>
            <div className={styles.biologicalAssessmentCardTextArrow}>
                <div>
                    <span className={styles.biologicalAssessmentCardBiologicalName}>{title}</span>
                    <p>{content || ""}</p>
                </div>
                {isSelected ? (
                    <CheckIcon className={styles.biologicalAssessmentCardArrowIcon} />
                ) : (
                    <ArrowForwardIcon className={styles.biologicalAssessmentCardArrowIcon} />
                )}
            </div>
        </div>
    );
}

export default BiologicalAssessmentCard;
