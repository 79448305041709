import { call, put, select } from "redux-saga/effects";
import { api_route, createApi } from "#services/api";
// Utils
import { showToast } from '#utils/sweetAlert'
import { formatContextAnswers } from "#utils/formatContextAnswers";
// Reducers
import { updateRecord, updatePatient } from "#reducers/recordReducer"
import { finished } from "#reducers/sessionReducer"

const selectRecord = (state) => state.records
const selectRecordId = (state) => state.records?.id;
const selectSession = (state) => state.sessions
const selectLaboratory = (state) => state.laboratories.laboratory

export const addRecordFields = function* addRecordFields() {
  const record = yield select(selectRecord)
  const session = yield select(selectSession)
  const laboratory = yield select(selectLaboratory)
  record.parcours_type = session.parcours
  record.list_of_steps = session.steps
  record.visited_steps = session.currentSteps
  record.current_step = session.currentStep
  if(laboratory) {
    record.laboratory_id = laboratory.id
  }
  return record
}

export const createRecord = function * createRecord() {
  const api = createApi()
  const record = yield call(addRecordFields)
  const response = yield call(api.post, api_route.records.create, record)
  if(response.ok && response.data){
    yield put(updateRecord(response.data.record))
  } else {
    throw 'Impossible de créer le dossier'
  }
}

export const saveRecord = function* saveRecord() { 
  const api = createApi()
  const record = yield call(addRecordFields)
  const response = yield call(api.put, api_route.records.update(record.id), { record })
  if(response.ok && response.data){
    yield put(updateRecord(response.data))
  } else {
    showToast('error', 'une erreur est survenue lors de la mise à jour du dossier')
  }
};

export const closeRecord = function* closeRecord() {
  const api = createApi()
  let record = yield select(selectRecord)
  const response = yield call(api.get, api_route.records.close_record(record.id))
  if(response.ok){
    yield put(finished())
  } else {
    showToast('error', 'une erreur est survenue lors de la clôture du dossier')
  }
}

export const saveNps = function* saveNps({nps}) {
  const api = createApi()
  let record = yield select(selectRecord)
  yield call(api.post, api_route.records.save_nps(record.id), {
    nps:{
      record_id: record.id,
      ...nps
    }
  })
}

export function* updatePatientSaga(action) {
  try {
    const api = createApi();
    const recordId = yield select(selectRecordId);

    if (!recordId) {
      showToast('error', 'Record ID introuvable');
      return;
    }

    const payload = {
      record: {
        patient: action.patient,
        patient_fill: true
      }
    };

    // Appel API pour mettre à jour le patient dans le backend
    const response = yield call(api.put, api_route.records.update(recordId), payload);

    if (response.ok && response.data) {
      // Mettre à jour l'état Redux avec les données mises à jour reçues du backend
      yield put(updatePatient(response.data.patient));
    } else {
      showToast('error', 'Erreur lors de la mise à jour du patient');
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour du patient:", error);
    showToast('error', 'Erreur lors de la mise à jour du patient');
  }
}

export function* saveContextAnswersSaga(action) {
  console.log("saveContextAnswersSaga:", action.payload);
  try {
    const api = createApi();
    const recordId = yield select(selectRecordId);
    const biologicals = yield select((state) => state.biological.biologicals);

    if (!recordId) {
      showToast("error", "Record ID introuvable");
      return;
    }

    // Reformater les réponses pour le envoyer au backend dans le bon format
    const formattedAnswers = yield call(formatContextAnswers, action.payload, biologicals);

    const payload = {
      record: {
        context_answers: formattedAnswers,
        context_answers_fill: true,
      },
    };

    // Appel API pour mettre à jour les réponses de contexte dans le backend
    console.log("Payload envoyé au backend :", payload);
    const response = yield call(api.post, api_route.records.save_context_answers(recordId), payload);

    if (response.ok) {
      console.log("Réponses de contexte mises à jour avec succès:", response.data);
    } else {
      console.log("Erreur lors de la mise à jour des réponses de contexte:", response);
      const errorMessage = response.data?.errors || "Erreur lors de la mise à jour des réponses de contexte";
      showToast("error", errorMessage);
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour des réponses de contexte:", error);
    showToast("error", "Erreur lors de la mise à jour des réponses de contexte");
  }
}
