import { call, put, select } from "redux-saga/effects";
import { api_route, createApi } from "#services/api";
import { showToast } from '#utils/sweetAlert';
import { setCriteria, setBiologicals } from "#reducers/biologicalReducer";
import { setCodeBilan, setPrescriptions } from '#reducers/recordReducer.js'
import { nextStep } from "#reducers/sessionReducer.js";

const selectPrescriptions = (state) => state.records.prescriptions;

export const getBiologicalCriteria = function* getBiologicalCriteria({code_ba}) {
  const api = createApi();
  try {
    const response = yield call(api.get, api_route.configs.criteria, { code_ba });

    if (response.ok && response.data && response.data.criteria) {
      const { criteria } = response.data;
      yield put(setCriteria(criteria));
    } else {
      throw 'Impossible de récupérer les critères';
    }
  } catch (error) {
    showToast('error', error);
  }
}

export const getBiologicals = function* getBiologicals() {
  const api = createApi();
  try {
    const response = yield call(api.get, api_route.configs.biologicals);

    if (response.ok && response.data && response.data.biologicals) {
      yield put(setBiologicals(response.data.biologicals));
    } else {
      throw 'Impossible de charger les bilans';
    }
  } catch (error) {
    showToast('error', error);
  }
}

export function* handleBiologicalAssessmentSelection({biologicalAssessment}) {
  yield call(getBiologicalCriteria, biologicalAssessment);
  yield call(generateBiologicalAssessmentPrescription, biologicalAssessment);
}

export const generateBiologicalAssessmentPrescription = function* generateBiologicalAssessmentPrescription(biologicalAssessment) {
  const presciptions = yield select(selectPrescriptions);
  let updatedPrescriptions = [...presciptions];
  const previousBiologicalAssessment = updatedPrescriptions.find(prescription => prescription.code_ba);
  if (!previousBiologicalAssessment) {
    const newPrescription = {
      code_ba: biologicalAssessment.code_ba,
      new: true
    }
    updatedPrescriptions.push(newPrescription);
  } else if (previousBiologicalAssessment.code_ba !== biologicalAssessment.code_ba) {
    previousBiologicalAssessment.destroy = true;
    const newPrescription = {
      code_ba: biologicalAssessment.code_ba,
      new: true
    }
    updatedPrescriptions.push(newPrescription);
  }
  yield put(setPrescriptions(updatedPrescriptions));
  yield put(setCodeBilan(biologicalAssessment.code_ba));
  yield put(nextStep());
}