// Saga: rootSaga.js
import { takeLatest, all } from 'redux-saga/effects';

/* Sagas */
import { initApp } from './configsSaga';
import { handleBiologicalAssessmentSelection, getBiologicalCriteria } from './biologicalSaga';
import { nextStep, prevStep, parcoursStep } from './navigationSaga';
import { saveRecord, closeRecord, saveNps, saveContextAnswersSaga } from './recordSaga';
import { uploadMutuelle, uploadPrescription, uploadPatientDocument, deletePatientDocument, deleteMutuelle, updatePrescriptionSaga } from './fileSaga';
import { syncDocuments } from './documentSaga';

export default function* rootSaga() {
  yield all([
    takeLatest('SET_CGU', initApp),
    takeLatest('SET_PARCOURS', parcoursStep),
    takeLatest('GET_CRITERIA', getBiologicalCriteria),
    takeLatest('SET_BIOLOGICAL_ASSESSMENT', handleBiologicalAssessmentSelection),
    takeLatest('SET_LABORATORY', syncDocuments),
    takeLatest('SET_PATIENT', saveRecord),
    takeLatest('NEXT_STEP', nextStep),
    takeLatest('PREV_STEP', prevStep),
    takeLatest('INCLUDE_PATIENT', nextStep),
    takeLatest('UPLOAD_MUTUELLE', uploadMutuelle),
    takeLatest('REMOVE_MUTUELLE', deleteMutuelle),
    takeLatest('UPLOAD_PRESCRIPTION', uploadPrescription),
    takeLatest('UPDATE_PRESCRIPTION', updatePrescriptionSaga),
    takeLatest('UPLOAD_PATIENT_DOCUMENT', uploadPatientDocument),
    takeLatest('REMOVE_PATIENT_DOCUMENT', deletePatientDocument),
    takeLatest('CLOSE_RECORD', closeRecord),
    takeLatest('SET_NPS', saveNps),
    takeLatest('UPDATE_NAVIGATION', saveRecord),
    takeLatest('SAVE_CONTEXT_ANSWERS', saveContextAnswersSaga),
  ]);
}
