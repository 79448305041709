import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { debounce } from 'lodash';
import cx from 'classix'
// Components
import { FormControl, TextField, Autocomplete, Tooltip } from "@mui/material";
import Button from "#components/global/Button";
import DateField from "#components/global/DateField";
import InfoIcon from "@mui/icons-material/Info";
import CustomModal from '#components/global/CustomModal';
// Reducers
import { updatePrescription } from '#reducers/fileReducer';
import { nextStep } from '#reducers/sessionReducer';
// Services
import { api_route, createApi } from "#services/api";
// Utils
import dateUtils from "#utils/dateUtils";
// Styles
import styles from "./Form.module.scss";

const PartialPrescriptionForm = ({ prescription, prescriptors_found, removeStoredFile, triggerFileSelect }) => {
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      prescriptionDate: dateUtils.formatDate( new Date(prescription.prescription_date )),
      prescriptor: { id: prescription.prescriptor_id, name: prescription.prescriptor },
    },
  });

  useEffect(() => { 
    setOptions(formatPrescriptors(prescriptors_found));
  }, [prescriptors_found]);

  // Transformation de prescriptors_found pour correspondre au format attendu par Autocomplete
  const formatPrescriptors = (prescriptors) => {
    return prescriptors.map((prescriptor) => ({
      id: prescriptor.rpps.toString(),
      name: prescriptor.full_name,
    }));
  }

  const fetchOptions = async (inputValue) => {
    try {
      const api = createApi();
      const response = await api.get(api_route.prescriptions.prescriptors_autocomplete, { criteria: inputValue });
      if (response.ok && response.data && response.data.doctors) {
        setOptions(formatPrescriptors(response.data.doctors)); // Mettre à jour l'état avec les données récupérées
      } else {
        throw new Error('Erreur de réponse de l\'API');
      }
    } catch (error) {
      setOptions(formatPrescriptors(prescriptors_found)); // Mettre à jour avec un tableau vide en cas d'erreur
    }
  };

  const debouncedFetchOptions = debounce((inputValue) => {
    fetchOptions(inputValue);
  }, 300);

  
  const onInputChangeHandler = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue && newInputValue.length >= 1) {
        debouncedFetchOptions(newInputValue);
      } else {
        setOptions(formatPrescriptors(prescriptors_found));
      }
    }
  };

  const onSubmit = (data) => {
    // Convertir la date de prescription de string à objet Date
    const prescriptionDate = new Date(data.prescriptionDate);
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    if (prescriptionDate.getTime() < oneYearAgo.getTime()) {
      setShowModal(true);
      return;
    }
    if (!prescription.id) {
      console.error("L'ID de la prescription est undefined");
      return;
    }
    
    const [day, month, year] = data.prescriptionDate.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    const updatedPrescription = {
      id: prescription.id,
      prescriptor: data.prescriptor.name,
      prescriptor_id: data.prescriptor.id,
      prescription_date: dateUtils.convertToUTC(formattedDate),
    };
    dispatch(updatePrescription(updatedPrescription));
    dispatch(nextStep());
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.prescriptionForm}>
          <div  className={`${styles.prescriptionFormGroup} ${styles.firstFormGroup}`}>
            <div className={styles.labelCol}>
              <label className={styles.labelPrescription}>
                Date de prescription *
              </label>
            </div>
            <div className={styles.inputCol}>
              <DateField
                control={control}
                name="prescriptionDate"
                required
                sx={{
                  backgroundColor: '#f5f5f5',
                  '& .MuiOutlinedInput-input': {
                    backgroundColor: '#f5f5f5',
                  },
                  '&:hover .MuiOutlinedInput-input': {
                    backgroundColor: '#f5f5f5',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-input': {
                      backgroundColor: 'white',
                    },
                  },
                }}  
              />
            </div>
          </div>
          <div className={`${styles.prescriptionFormGroup} ${styles.secondFormGroup}`}>
            <div className={styles.labelCol}>
              <label className={styles.labelPrescription}>
                Nom du prescripteur *
              </label>
            </div>
            <div className={styles.inputCol}>
              <FormControl fullWidth>
                <div className={styles.autocompleteContainer}>
                  <Controller
                    name="prescriptor"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                      <Autocomplete
                        options={options}
                        style={{ width: "100%" }}
                        getOptionLabel={(option) =>
                          option.id ? `${option.name} (${option.id})` : option.name
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        onInputChange={onInputChangeHandler}
                        onChange={(event, newValue) => {
                          onChange(newValue || "");
                        }}
                        renderOption={(props, option) => (
                          <li {...props} style={{ fontSize: "1.4rem" }}>
                            {option.name} {option.id}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              backgroundColor: '#f5f5f5',
                              '& .MuiOutlinedInput-input': {
                                backgroundColor: '#f5f5f5',
                              },
                              '&:hover .MuiOutlinedInput-input': {
                                backgroundColor: '#f5f5f5',
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused .MuiOutlinedInput-input': {
                                  backgroundColor: 'white',
                                },
                              },
                            }}                        
                          />
                        )}
                        {...field}
                      />
                    )}
                  />
                  <Tooltip
                    title="Sélectionnez ou tapez le nom de votre prescripteur"
                    arrow
                    classes={{ tooltip: styles.customTooltip }}
                  >
                    <InfoIcon className={cx(styles.infoIcon, styles.infoIconWarning)} />
                  </Tooltip>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={styles.alignRight}>
          <Button
            type="submit"
            text="ENREGISTRER"
            className="primary"
          />
        </div>
      </form>
      <CustomModal
        open={showModal}
        handleClose={handleCloseModal}
        title="Vérifier la date de prescription"
      >
        <p className={styles.pContentForm}>La date de votre prescription est antérieure à plus d'un an. Si cela est dû à une erreur de saisie, veuillez corriger la date. Autrement, nous vous invitons à fournir une prescription plus récente.</p><br />
        <div className={styles.buttonsDiv}>
          <Button 
            className="primary"
            onClick={handleCloseModal} 
            text="Corriger la date"
          />
          <Button 
            className="primary"
            onClick={() => {
              removeStoredFile("prescription");
              triggerFileSelect();
            }} 
            text="Modifier l'ordonnance" 
          />
        </div>
      </CustomModal>
    </>
  );
};

export default PartialPrescriptionForm;
