import React from 'react';
import styles from './SmallWhiteCard.module.scss';
//Utils
import useIsDesktop from '#utils/useInDesktop'

function SmallWhiteCard({ title, children, image, desktopWidth }) {
    const isDesktop = useIsDesktop();
    const cardStyle = isDesktop && desktopWidth ? { width: `${desktopWidth}%` } : {};

    return (
        <div className={styles.smallWhiteCard} style={cardStyle}>
            <div className={styles.topRow}>
                {title && (
                    <p className={styles.title}>{title}</p>
                )}
                <div className={styles.content}>
                    {children}
                </div>
            </div>
            {image && (
                <div className={styles.bottomRow}>
                    <img className={styles.pictoCard} src={image} alt={title} />
                </div>
            )}
        </div>
    );
}

export default SmallWhiteCard;
