import React, { useState } from 'react';
//Components
import Button from "#components/global/Button";
import CustomModal from '#components/global/CustomModal';
//Styles
import styles from "./Form.module.scss";

const PartialIdentityForm = ({ patient, onSubmit, submitButtonText, isModalOpen, handleClose, verificationData }) => {
    const [errors, setErrors] = useState({ firstName: '', lastName: '' });
    const [firstName, setFirstName] = useState(patient.first_name);
    const [lastName, setLastName] = useState(patient.last_name);
    const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
    const [isLastNameFocused, setIsLastNameFocused] = useState(false);

    const handleFormSubmit = (event) => {
        event.preventDefault();
    
        let newErrors = { firstName: '', lastName: '' };
        let isValid = true;
    
        if (!firstName.trim()) {
            newErrors.firstName = 'Format non valide';
            isValid = false;
        }
        if (!lastName.trim()) {
            newErrors.lastName = 'Format non valide';
            isValid = false;
        }
    
        setErrors(newErrors);
    
        if (isValid) {
            // Calcul des nouvelles valeurs
            const firstNameFound = verificationData.parsedText?.toLowerCase().includes(firstName.toLowerCase()) || false;
            const lastNameFound = verificationData.parsedText?.toLowerCase().includes(lastName.toLowerCase()) || false;
            
            onSubmit(firstName.trim(), lastName.trim(), firstNameFound, lastNameFound);
        }
    };
    
    return (
        <CustomModal 
            open={isModalOpen} 
            handleClose={handleClose}
            title="Corriger mes informations d'identité"
        >
            <p className={styles.pContentForm}>Vos informations doivent correspondre à celles de l’ordonnance. Si ce n’est pas le cas, elles devront être vérifiées au laboratoire le jour de votre examen.</p>
            <form id="partialIdentityForm" onSubmit={handleFormSubmit}>
                <label htmlFor="firstName">Prénom</label>
                <input 
                    type="text" 
                    name="firstName" 
                    value={firstName}
                    placeholder={!isFirstNameFocused ? patient.first_name : ''} 
                    onFocus={() => setIsFirstNameFocused(true)}
                    onBlur={() => setIsFirstNameFocused(false)}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && <p className={styles.errorMessage}>{errors.firstName}</p>}

                <label htmlFor="lastName">Nom</label>
                <input 
                    type="text" 
                    name="lastName" 
                    value={lastName}
                    placeholder={!isLastNameFocused ? patient.last_name : ''} 
                    onFocus={() => setIsLastNameFocused(true)}
                    onBlur={() => setIsLastNameFocused(false)}
                    onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <p className={styles.errorMessage}>{errors.lastName}</p>}

                <Button type="submit" text="ENREGISTRER" className="primary" />
            </form>
        </CustomModal>
    );
};

export default PartialIdentityForm;
