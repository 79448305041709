import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// Components
import Button from '#components/global/Button';
import LargeWhiteCard from '#components/global/LargeWhiteCard';
import Alert from '#components/global/Alert';
// Reducer
import { saveContextAnswers } from "#reducers/recordReducer";
import { nextStep } from '#reducers/sessionReducer';
import { contextAnswersSelector } from "#reducers/selectors";
// Styles
import styles from './Form.module.scss';

// Images
// import contextImage from '#assets/images/context_picto.png';

const ContextForm = () => {
  const dispatch = useDispatch();
  
  // Récupérer les biologicals et prescriptions depuis le state
  const { biologicals, prescriptions } = useSelector(state => ({
    biologicals: state.biological.biologicals,
    prescriptions: state.records.prescriptions
  }));
  
  // Prendre la dernière prescription
  const lastPrescription = prescriptions?.[prescriptions.length - 1];
  
  // Récupérer les contextAnswers existantes depuis Redux et sessionStorage
  const contextAnswers = useSelector(contextAnswersSelector) || {};
  const lsContextAnswers = JSON.parse(sessionStorage.getItem("contextForm") || "{}");

    // Filtrer les biologicals dont le code correspond aux loinc_codes dans la prescription
    const biologicalsWithContextQuestions = biologicals.filter(biological =>
      lastPrescription.loinc_codes.includes(biological.code) &&
      biological.context_questions.length > 0
    );

  // Utiliser react-hook-form pour gérer le formulaire, initialisé avec les valeurs existantes
  const { control, handleSubmit, watch, setValue, getValues, formState: { errors, isValid }, trigger } = useForm({
    defaultValues: {
      ...contextAnswers,
      ...lsContextAnswers
    },
    mode: 'onChange',
  });

  // Surveiller les modifications du formulaire en temps réel
  const currentFormData = watch();
  
  // État pour savoir si le formulaire a été soumis
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Enregistrer les modifications dans sessionStorage en temps réel
  useEffect(() => {
    if (currentFormData) {
      sessionStorage.setItem("contextForm", JSON.stringify(currentFormData));
    }
  }, [currentFormData]);

  // Fonction de soumission du formulaire
  const onSubmit = (data) => {
    const answers = Object.entries(data).reduce((acc, [questionId, answer]) => {
      const question = biologicalsWithContextQuestions
        .flatMap(bio => bio.context_questions)
        .find(q => q.id === parseInt(questionId, 10));
      
      let contextValueId;
      let contextLabel;
  
      if (question?.question_type === 'checkbox') {
        // Pour les checkbox, récupérer un tableau d'ID non vide
        contextValueId = Array.isArray(answer) ? answer.filter(id => id !== null) : [];
        if (contextValueId.length === 0) return acc; // Ignorer si aucune option n'est sélectionnée
      } else if (question?.question_type === 'radio') {
        // Pour les radio, récupérer l'ID et le label de l'option sélectionnée
        contextValueId = answer || null;
        const selectedOption = question.context_values.find(value => value.id === contextValueId);
        contextLabel = selectedOption ? selectedOption.value_label : "";
        if (!contextValueId) return acc; // Ignorer si aucune option n'est sélectionnée
      } else if (question?.question_type === 'text') {
        // Pour les questions de type "text"
        contextValueId = null;
        contextLabel = typeof answer === 'string' ? answer : "";
        if (contextLabel === "") return acc; // Ignorer si le champ est vide
      }
  
      // Ajouter la réponse collectée seulement si elle est valide
      acc.push({
        context_question_id: parseInt(questionId, 10),
        context_value_id: contextValueId,
        context_value_label: contextLabel,
        answer_value: question?.question_label || "",
      });
  
      return acc;
    }, []);
  
    console.log("Réponses collectées avant l'envoi :", answers);
    dispatch(saveContextAnswers(answers));
    dispatch(nextStep());
  };

  // Afficher un message d'erreur si des questions obligatoires ne sont pas remplies
  const handleValidation = async () => {
    const result = await trigger(); // Valide le formulaire
    setFormSubmitted(true);

    // Si la validation échoue, on scroll vers le haut pour afficher l'alerte
    if (!result) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainerColumn}>
      {/* Afficher l'alerte uniquement après soumission et s'il y a des erreurs */}
      {formSubmitted && !isValid && (
        <Alert 
          type="error" 
          message="Veuillez répondre à toutes les questions obligatoires."
          desktopWidth={80}
        />
      )}
      <LargeWhiteCard 
        // image={contextImage}
        desktopWidth={80}
      >
        {biologicalsWithContextQuestions.length > 0 ? (
          <div>
            {biologicalsWithContextQuestions.map(biological => (
              <div key={biological.id}>
                <h2>{`Questions pour l'analyse : ${biological.code}`}</h2><br />
                {biological.context_questions
                  .sort((a, b) => a.question_priority - b.question_priority)
                  .map(question => (
                    <div key={question.id} className={styles.contextQuestionGroup}>
                      <label className={styles.questionLabel}>
                        {question.question_label}
                        {question.mandatory && <span className={styles.redText}> *</span>}
                      </label>
                      {renderQuestionField(question, control, errors, setValue, getValues)}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ) : (
          <p>Aucune question contextuelle à afficher.</p>
        )}
        <div className={styles.requiredNotice}>
          <span className={styles.redText}>*</span> <span>Questions obligatoires</span>
        </div>
      </LargeWhiteCard>

      <div className="single-btn-container btn-center">
        <Button className="primary" type="submit" text="ENREGISTRER" onClick={handleValidation} />
      </div>
    </form>
  );
};

// Fonction pour afficher les différents types de champs en fonction de la question
const renderQuestionField = (question, control, errors, setValue, getValues) => {
  switch (question.question_type) {
    case 'radio':
      return (
        <Controller
          name={`${question.id}`}
          control={control}
          rules={{ required: question.mandatory }}
          render={({ field }) => (
            <div className={styles.formRadioGroup}>
              {question.context_values.map(value => (
                <div key={value.id} className={styles.formCheck}>
                  <input
                    type="radio"
                    id={`context_answer_${question.id}_${value.id}`}
                    {...field}
                    value={value.id}
                    checked={getValues(`${question.id}`) === value.id}
                    onChange={(e) => {
                      setValue(`${question.id}`, value.id);
                      field.onChange(value.id);
                    }}
                    className="form-check-input"
                  />
                  <label htmlFor={`context_answer_${question.id}_${value.id}`} className={styles.formCheckLabel}>
                    {value.value_label}
                  </label>
                </div>
              ))}
            </div>
          )}
        />
      );
    case 'checkbox':
      return (
        <Controller
          name={`${question.id}`}
          control={control}
          rules={{ required: question.mandatory }}
          render={({ field }) => (
            <div>
              {question.context_values.map(value => (
                <div key={value.id} className={styles.formCheck}>
                  <input
                    type="checkbox"
                    id={`context_answer_${question.id}_${value.id}`}
                    {...field}
                    value={value.id}
                    checked={getValues(`${question.id}`)?.includes(value.id) || false}
                    onChange={(e) => {
                      const currentValues = getValues(`${question.id}`) || [];
                      const updatedValues = e.target.checked
                        ? [...currentValues, value.id]
                        : currentValues.filter(val => val !== value.id);
                      setValue(`${question.id}`, updatedValues);
                      field.onChange(updatedValues);
                    }}
                    className={styles.formCheckInput}
                  />
                  <label htmlFor={`context_answer_${question.id}_${value.id}`} className={styles.formCheckLabel}>
                    {value.value_label}
                  </label>
                </div>
              ))}
            </div>
          )}
        />
      );
    case 'text':
      return (
        <Controller
          name={`${question.id}`}
          control={control}
          rules={{ required: question.mandatory }}
          render={({ field }) => (
            <div>
              <input
                type="text"
                id={`context_answer_${question.id}`}
                {...field}
                className={styles.formTextInput}
                onChange={(e) => {
                  setValue(`${question.id}`, e.target.value);
                  field.onChange(e);
                }}
              />
            </div>
          )}
        />
      );
    default:
      return <p>Type de question non supporté: {question.question_type}</p>;
  }
};

export default ContextForm;
