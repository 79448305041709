import { createTheme } from "@mui/material";
import cssVariables from "./_variables.module.scss";

export const lightTheme = createTheme({
  zIndex: {
    drawer: 0, //pour que la navbar soit sous les popups de preview
  },
  shape: {
    borderRadius: 15
  },
  palette: {
    mode: "light",
    primary: {
      main: cssVariables.colorPrimary,
      contrastText: cssVariables.colorWhite,
    },
    secondary: {
      main: cssVariables.colorYellow,
      contrastText: cssVariables.colorWhite,
    },
    white: {
      main: cssVariables.colorWhite,
      contrastText: cssVariables.colorBlack,
    },
    black: {
      main: cssVariables.colorBlack,
      contrastText: cssVariables.colorWhite,
    },
    success: {
      main: cssVariables.colorSuccess,
    },
    error: {
      main: cssVariables.colorError,
    },
    text: {
      primary: cssVariables.colorTextPrimary,
      secondary: cssVariables.colorTextSecondary,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
          padding: '0 20px',
          boxShadow: 'none',
          border: 'none',
        },
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        margin: "dense",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          borderRadius: cssVariables.borderRadiusShape,
          "& .MuiInputLabel-outlined": {
            fontSize: "1.5rem",
            transform: "translate(0rem, -2.5rem)",
            transformOrigin: "top left",
            fontWeight: 500,
          },
          "& .MuiInputBase-input": {
            fontSize: "1.5rem",
            textAlign: "left",
            backgroundColor: cssVariables.colorWhite,
            borderRadius: cssVariables.borderRadiusShape
          },
          "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "1.4rem",
            marginLeft: '0'
          },
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            fontSize: "1.5rem",
            transform: "translate(0rem, -2.5rem)",
          },
          '& .MuiOutlinedInput-notchedOutline legend span': {
            display: 'none',
          },
          '& fieldset': {
            borderRadius: cssVariables.borderRadiusShape,
            borderColor: 'transparent'
          },
          '& label.Mui-focused': {
            color: cssVariables.colorBlack
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: cssVariables.colorYellow         
            },
            '&:hover fieldset': {
              borderColor: cssVariables.colorYellow
            },
            '&.Mui-disabled fieldset': {
              borderColor: 'transparent',
            },
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: cssVariables.colorBlack,
          '&.Mui-error': {
            color: cssVariables.colorBlack
          },
        },
        asterisk: {
          color: cssVariables.colorPrimary,
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',  // Augmente la taille de la police des options
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          padding: '15px 24px',
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
          '@media (max-width: 960px)': {
            marginBottom: '10px',
          },
        },
      },
    },  
    MuiGrid: {
      styleOverrides: {
        root: {
          '@media (max-width: 960px)': {
            marginBottom: '8px',
          },
        },
      },
    },  
  }
});
