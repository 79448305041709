import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './storeConfig';

export const GlobalStateContext = React.createContext();

export const GlobalStateProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStateContext.Provider value={{ store, persistor }}>
          {children}
        </GlobalStateContext.Provider>
      </PersistGate>
    </Provider>
  );
};