import { call, put, takeLatest } from 'redux-saga/effects';
import { createApi } from '#services/api';
import { setDocuments } from '#reducers/sessionReducer';
import { showToast } from '#utils/sweetAlert';

function fetchDocumentsFromApi(laboratoryCode) {
  const api = createApi();
  return api.get(`/api_web/v1/configs/documents?laboratory_code=${laboratoryCode}`);
}

export function* syncDocuments({ laboratory }) {
  try {
    const response = yield call(fetchDocumentsFromApi, laboratory.code);
    if (response.ok && response.data) {
      const documents = response.data.documents;
      yield put(setDocuments(documents));
    } else {
      throw new Error('Failed to fetch documents');
    }
  } catch (error) {
    showToast('error', error.message);
  }
}

export default function* watchSyncDocuments() {
  yield takeLatest('SET_LABORATORY', syncDocuments);
}
