// ParcoursCard.js
import React from 'react';
import styles from './ParcoursCard.module.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';

function ParcoursCard({ title, children, onClick, color,isAnyCardSelected, isSelected  }) {
    const cardStyle = {
        backgroundColor: isSelected ? color : (isAnyCardSelected ? "#CDCDCD" : color)
    };

    return (
        <div className={styles.parcoursCard} onClick={onClick} style={cardStyle}>
            <div className={styles.parcoursCardContent}>
                <div className={styles.parcoursCardTitle}>
                    {title}
                </div>
                <div className={styles.parcoursCardTextArrow}>
                    {children}
                    {isSelected ? (
                        <CheckIcon className={styles.parcoursCardArrowIcon} />
                    ) : (
                        <ArrowForwardIcon className={styles.parcoursCardArrowIcon} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ParcoursCard;
