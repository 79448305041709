import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import cx from 'classix';
import { ButtonGroup, Button as MuiButton } from "@mui/material";

// Components
import Button from "#components/global/Button";
// Reducer
import { setNps } from "#reducers/sessionReducer";
// Styles
import styles from "./Form.module.scss";

const NPSForm = ({ setShowNpsForm }) => {
  const [score, setScore] = useState(-1);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const getButtonClass = (number) => {
    if (number === score) {
      switch (true) {
        case number <= 2:
          return styles.buttonRed;
        case number <= 4:
          return styles.buttonOrange;
        case number <= 6:
          return styles.buttonYellow;
        case number <= 8:
          return styles.buttonLightGreen;
        default:
          return styles.buttonGreen;
      }
    }
  };

  const handleSubmit = () => {
    dispatch(setNps({ score, comment }))
    setShowNpsForm(false)
  };

  return (
    <div className={cx('mt-3', styles.npsContainer)}>
      <h4> <strong>Votre avis compte pour nous !</strong> </h4>
      <p className="mt-2 mb-2"> 
        Quelle est la probabilité que vous recommandiez ce service à un ami ?
      </p>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        className={styles.responsiveButtonGroup}
      >
        {[...Array(11).keys()].map((number) => (
          <MuiButton
            key={number}
            onClick={() => setScore(number)}
            variant={score === number ? "contained" : "outlined"}
            className={cx(styles.npsNumber, `nps-${number}` ,getButtonClass(number))}
          >
            {number}
          </MuiButton>
        ))}
      </ButtonGroup>
      {score > -1 && (
        <>
          <div className="mt-3">
            <TextField
              InputLabelProps={{
                className: styles.npsLabel,
              }}
              className={styles.npsComment}
              placeholder="Ajouter un commentaire..."
              color="secondary"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="single-btn-container btn-center">
            <Button
              className="primary"
              text="ENVOYER"
              onClick={handleSubmit}
            >
              Valider
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NPSForm;
