import { create } from 'apisauce';

export const createApi = ({ timeout = 5000, headers = {} } = {}) => {
  return create({
    baseURL: window.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
    timeout: timeout,
    headers: headers
  });
};

export const api_route = {
  configs: {
    steps: '/api_web/v1/configs/steps',
    biological_assessments: '/api_web/v1/configs/biological_assessments',
    criteria: '/api_web/v1/configs/criteria',
    biologicals: '/api_web/v1/configs/biologicals'
  },
  laboratories: {
    index: 'api_web/v1/laboratories'
  },
  records: {
    create: 'api_web/v1/records',
    update: (record_id) => `api_web/v1/records/${record_id}`,
    save_laboratory: (record_id) => `api_web/v1/records/${record_id}/save_laboratory`,
    close_record: (record_id) => `api_web/v1/records/${record_id}/close_record`,
    save_context_answers: (record_id) => `api_web/v1/records/${record_id}/save_context_answers`,
    save_nps: (record_id) => `api_web/v1/records/${record_id}/save_nps`
  },
  files: {
    save_mutuelle: 'api_web/v1/files/save_mutuelle',
    delete_mutuelle: 'api_web/v1/files/delete_mutuelle',
    save_prescription: 'api_web/v1/files/save_prescription',
    save_patient_document: 'api_web/v1/files/save_patient_document',
    delete_patient_document: 'api_web/v1/files/delete_patient_document',
    fileUrl: (filePath, patientId, document_id = null) => `${window.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL}${filePath}?patient_id=${patientId}&document_id=${document_id}`
  },
  prescriptions: {
    prescriptors_autocomplete: 'api_web/v1/prescriptions/prescriptors_autocomplete'
  }
}
