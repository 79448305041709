const initialState = {
  patient: {
    real_gender: "",
    first_name: "",
    last_name: "",
    birth_name: "",
    birthday: "",
    security_number: "",
    address: "",
    // address_complement: "",
    zip: "",
    city: "",
    email: "",
    mobile: "",
    identical_birthname: true
  },
  prescriptions: [],
  patient_fill: false,
  prescriptions_updated: false,
  verification: null,
  contextAnswers: {},
}

const SET_PATIENT = 'SET_PATIENT'
const UPDATE_PATIENT = 'UPDATE_PATIENT'
const UPDATE_RECORD = 'UPDATE_RECORD'
const CLOSE_RECORD = 'CLOSE_RECORD'
const CODE_BILAN = 'CODE_BILAN'
const SET_PRESCRIPTIONS = 'SET_PRESCRIPTIONS'
const REMOVE_BA = 'REMOVE_BA'
const UPDATE_VERIFICATION = 'UPDATE_VERIFICATION'
const SAVE_CONTEXT_ANSWERS = 'SAVE_CONTEXT_ANSWERS'

const recordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATIENT: {
      return { ...state, patient: { ...state.patient, ...action.patient}, patient_fill: true};
    }
    case UPDATE_PATIENT: {
      return { ...state, patient: { ...state.patient, ...action.patient }, patient_fill: true };
    }
    case UPDATE_RECORD: {
      return { ...state, ...action.record, patient: { ...state.patient, ...action.record.patient }, prescriptions: action.record.prescriptions};
    }
    case CLOSE_RECORD: {
      return state;
    }
    case CODE_BILAN: {
      return { ...state, code_bilan: action.code_bilan };
    }
    case SET_PRESCRIPTIONS: {
      return { ...state, prescriptions: action.prescriptions, prescriptions_updated: true};
    }
    case REMOVE_BA: {
      const updatedPrescriptions = state.prescriptions.reduce((acc, prescription) => {
        if (prescription.code_ba) {
          if (!prescription.new) {
            acc.push({ ...prescription, destroy: true });
          }
        } else {
          acc.push(prescription);
        }
        return acc;
      }, []);
      
      return { ...state, code_bilan: null, prescriptions: updatedPrescriptions, prescriptions_updated: true};
    }
    case UPDATE_VERIFICATION: {
      return { ...state, verification: action.verification };
    }
    case SAVE_CONTEXT_ANSWERS: {
      return { ...state, contextAnswers: action.payload };
    }

    default:
      return state;
  }
}

export default recordReducer

export const setPatient = (patient) => {
  return {
    type: SET_PATIENT,
    patient
  };
};

export const updatePatient = (patient) => {
  return {
    type: UPDATE_PATIENT,
    patient
  };
};

export const updateRecord = (record) => {
  return {
    type: UPDATE_RECORD,
    record
  }
}

export const closeRecord = () => {
  return {
    type: CLOSE_RECORD
  }
}

export const setCodeBilan = (code_bilan) => {
  return {
    type: CODE_BILAN,
    code_bilan
  }
}

export const setPrescriptions = (prescriptions) => {
  return {
    type: SET_PRESCRIPTIONS,
    prescriptions
  }
}

export const removeBA = () => {
  return {
    type: REMOVE_BA
  }
}

export const updateVerification = (verification) => {
  return {
      type: UPDATE_VERIFICATION,
      verification,
  };
};

export const saveContextAnswers = (answers) => ({
  type: SAVE_CONTEXT_ANSWERS,
  payload: answers,
});