import { select, put, call } from 'redux-saga/effects'

// Action
import { updateNavigation } from '#reducers/sessionReducer'
import { getCriteria } from '#reducers/biologicalReducer.js'
import { removeBA } from '#reducers/recordReducer.js'
import { createSelector } from 'reselect';

const selectSteps = (state) => state.sessions.steps 
const selectcurrentSteps = (state) => state.sessions.currentSteps
const selectCurrentStep = (state) => state.sessions.currentStep

export const hasContextQuestionsSelector = createSelector(
  (state) => state.records.prescriptions || [],
  (state) => state.biological.biologicals || [],
  (prescriptions, biologicals) => {
    const lastPrescription = prescriptions[prescriptions.length - 1];

    return (
      Array.isArray(lastPrescription?.loinc_codes) &&
      lastPrescription.loinc_codes.some((code) => {
        const biological = biologicals.find((b) => b.code === code);
        return biological && biological.context_questions && biological.context_questions.length > 0;
      })
    );
  }
);


export const nextStep = function * nextStep(){
  const currentStep = yield select(selectCurrentStep)
  const currentSteps = yield select(selectcurrentSteps)
  const steps = yield select(selectSteps)

  const stepsLen = steps.length
  const currentStepIndex = steps.indexOf(currentStep)

  if(currentStepIndex < 0) {
    yield put(updateNavigation('parcours', ['parcours']))
    console.error('Uninitialised state')
  } else if ( currentStepIndex === stepsLen -1 ) {
    yield put(updateNavigation(currentStep, currentSteps))
  } else {
    const newStep = steps[currentStepIndex + 1]
    let newCurrentSteps = [...currentSteps]
    if(!newCurrentSteps.includes(newStep)) {
      newCurrentSteps.push(newStep)
    }
    yield put(updateNavigation(newStep, newCurrentSteps))
  }
}

export const prevStep = function * prevStep(){
  const currentStep = yield select(selectCurrentStep)
  const currentSteps = yield select(selectcurrentSteps)
  const steps = yield select(selectSteps)
  const currentStepIndex = steps.indexOf(currentStep)

  if (currentStepIndex < 0) {
    yield put(updateNavigation('parcours', ['parcours']));
    console.error('Uninitialised state');
    return;
  }

  // Si l'étape précédente est "context"
  if (currentStepIndex > 0) {
    const previousStep = steps[currentStepIndex - 1];

    if (previousStep === 'context') {
      const hasContextQuestions = yield select(hasContextQuestionsSelector);

      if (hasContextQuestions) {
        yield put(updateNavigation('context', currentSteps));
        return;
      } else if (currentStepIndex > 1) {
        const newStep = steps[currentStepIndex - 2];
        yield put(updateNavigation(newStep, currentSteps));
        return;
      }
    }
  }

  // Si on est à la première étape ou qu'il n'y a pas de logique spécifique pour l'étape précédente
  if (currentStepIndex === 0) {
    yield put(updateNavigation(currentStep, currentSteps));
  } else {
    const newStep = steps[currentStepIndex - 1];
    yield put(updateNavigation(newStep, currentSteps));
  }
};

export const parcoursStep = function * parcoursStep({parcours}) {
  if(parcours === 'with_prescription') {
    yield put(removeBA())
    yield put(getCriteria('*'))
    yield call(nextStep)
  }
}