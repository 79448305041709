import React from 'react';
import styles from './LargeWhiteCard.module.scss';
// Utils
import useIsDesktop from '#utils/useInDesktop'

function LargeWhiteCard({ title, children, image, desktopWidth, justifyContent, leftColumnWidth, rightColumnWidth }) {
    const isDesktop = useIsDesktop();
    const cardStyle = {
        ...(isDesktop && desktopWidth ? { width: `${desktopWidth}%` } : {}),
        justifyContent: justifyContent || 'center',
    };

    const leftStyle = {
        width: leftColumnWidth ? `${leftColumnWidth}%` : 'auto',
    };

    const rightStyle = {
        width: rightColumnWidth ? `${rightColumnWidth}%` : 'auto',
    };

    return (
        <div className={styles.largeWhiteCard} style={cardStyle}>
            <div className={styles.leftColumn} style={leftStyle}>
                {title && (
                    <p className={styles.title}>{title}</p>
                )}
                {children}
            </div>
            {image && (
                <div className={styles.rightColumn} style={rightStyle}>
                    <img className={styles.pictoCard} src={image} alt={title} />
                </div>
            )}
        </div>
    );
}

export default LargeWhiteCard;
