import { useSelector } from 'react-redux';
import { biologicalAssessmentSelector } from '#reducers/selectors';

export function useCanGoStep() {
  const steps = useSelector(state => state.sessions.steps);
  const currentSteps = useSelector(state => state.sessions.currentSteps);
  const currentStep = useSelector(state => state.sessions.currentStep);
  const registrationFinished = useSelector(state => state.sessions.registrationFinished);
  const biologicalAssessment = useSelector(biologicalAssessmentSelector)
  const parcours = useSelector(state => state.sessions.parcours);

  if(registrationFinished) {
    return [ false, false ];
  }
  const currentIndex = steps.indexOf(currentStep);
  const nextStep = steps[currentIndex + 1];
  const hasNextStepValidated = currentSteps.includes(nextStep);
  const isParcoursValid = parcours === 'with_prescription' || (parcours === 'without_prescription' && biologicalAssessment && biologicalAssessment.code_ba)
  const isValidNextStep = hasNextStepValidated && isParcoursValid
  const isFirstStep = (currentStep === steps[0] || (currentIndex < 0));

  return [ !isFirstStep, isValidNextStep ];
}