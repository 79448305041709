import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { excludePatient, includePatient } from '#reducers/sessionReducer';
// Components
import Button from '#components/global/Button';
import Alert from '#components/global/Alert';
import LargeWhiteCard from '#components/global/LargeWhiteCard';
// Styles
import styles from './Form.module.scss';
// Images
import criteriaImage from '#assets/images/criteria_picto.png';

const CriteriaForm = () => {
  const dispatch = useDispatch();
  const { exclusionCriteria, criteria } = useSelector(state => ({
    exclusionCriteria: state.sessions.exclusionCriteria,
    criteria: state.biological.criteria // Utiliser les critères du biologicalReducer
  }));

  return (
    <div className={styles.formContainerColumn}>
      <LargeWhiteCard 
        title="Votre situation correspond-elle à l'un des cas suivants ?"
        image={criteriaImage}
        desktopWidth={70} 
      >
        <ul className={styles.redBulletList}>
          {criteria && criteria.length > 0 ? (
            criteria.map((criterion, index) => (
              <li key={index}>{criterion.label}</li>
            ))
          ) : (
            <li>Chargement des critères...</li>
          )}
        </ul>
        <div className={styles.buttonsDiv}>
          <Button 
            className="secondary"
            text="OUI" 
            onClick={() => { dispatch(excludePatient()) }}
          />
          <span style={{ margin: "0 10px" }}></span>
          <Button 
            className="primary"
            text="NON" 
            onClick={() => { dispatch(includePatient()) }}
          />
        </div>
      </LargeWhiteCard>
      {exclusionCriteria === 'yes' && (
        <Alert 
          type="error" 
          message="L'enregistrement en ligne n'est pas disponible pour votre cas. Merci de vous rendre directement à l'accueil du laboratoire."
          desktopWidth={70}
        />
      )}
    </div>
  );
};

export default CriteriaForm;
