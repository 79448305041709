// Styles
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <h5>Le Pass</h5>
    </header>
  )
}

export default Header