//Components
import LayoutBase from "#layouts/LayoutBase";
import LayoutBackground from "#layouts/LayoutBackground";
import Header from "#components/header/Header";
import Content from "#components/content/Content";
//Utils
import useIsDesktop from '#utils/useInDesktop'

const Record = () => { 
  const isDesktop = useIsDesktop();
  return (
    <LayoutBase>
      <LayoutBackground pageType="Record">
        {isDesktop && <Header />}
        <Content /> 
      </LayoutBackground>
    </LayoutBase>
  );
}

export default Record;
