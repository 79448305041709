import React from 'react';
import styles from "./Footer.module.scss";

const Footer = ({ className }) => {
  const footerClass = `${styles.footer} ${className || 'footer'}`;

  return (
    <footer  className={footerClass}>
      <div className={styles.footerLinks}>
        <a href="https://my-unilabs.fr/cgu-mentions-legales" target="_blank" rel="noreferrer">Conditions Générales d’Utilisations</a>
        <a className="mx-2" href="https://my-unilabs.fr/protection-des-donnees" target="_blank" rel="noreferrer">Protection des Données</a>
      </div>
        <p>© Unilabs 2024  |  Powered by <span>7EGEND</span></p>
    </footer>
  )
};

export default Footer;