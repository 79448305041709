import React from 'react';
import styles from './CustomModal.module.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const style = theme => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    margin: 1,
    borderRadius: '10px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    },
});

function CustomModal({ open, handleClose, children, title }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="custom-modal-title"
            aria-describedby="custom-modal-description"
        >
            <Box sx={style} className={styles.customModal}>
                <IconButton 
                    aria-label="close" 
                    onClick={handleClose} 
                    className={styles.closeButton}
                >
                    <CloseIcon className={styles.closeIcon}/>
                </IconButton>
                {title && (
                    <Typography id="custom-modal-title" variant="h6" component="h3" className={styles.modalTitle}>
                        {title}
                    </Typography>
                )}
                {children}
            </Box>
        </Modal>
    );
}

export default CustomModal;
